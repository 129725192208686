import React from 'react'

function Footer() {
  return (
    <div>
     <footer className="main-footer @@extraClassName">
  <div className="main-footer__bg" style={{backgroundImage: 'url(assets/images/backgrounds/footer-bg.png)'}} />
  {/* /.main-footer__bg */}
  <div className="container">
    <div className="main-footer__top row">
      <div className="col-lg-4 col-sm-5">
        <div className="main-footer__logo-box">
        <img src="assets/images/logo.png" alt="Trevlo HTML" width={110}/>
              <span className='text-white fs-5 fw-semibold'>PAK QURTUBA</span>
        </div>{/* /.main-footer__logo-box */}
      </div>
      <div className="col-lg-8 col-sm-7">
        <ul className="main-footer__social">
          <li className="main-footer__social-item">
            <a href="https://facebook.com" className="main-footer__social-link"><i className="fab fa-facebook-f" /></a>
          </li>
          <li className="main-footer__social-item">
            <a href="https://linkedin.com" className="main-footer__social-link"><i className="fab fa-linkedin-in" /></a>
          </li>
          <li className="main-footer__social-item">
            <a href="https://twitter.com" className="main-footer__social-link"><i className="fab fa-twitter" /></a>
          </li>
          <li className="main-footer__social-item">
            <a href="https://youtube.com" className="main-footer__social-link"><i className="fab fa-youtube" /></a>
          </li>
        </ul>{/* /.main-footer__logo-box */}
      </div>
      <div className="col-12">
        <div className="main-footer__line" />{/* /.main-footer__line */}
      </div>{/* /.col-12 */}
    </div>{/* /.main-footer__top */}
    <div className="row">
      <div className="col-lg-3 col-md-4 col-sm-6 col-xl-2 wow animated fadeInUp" data-wow-delay="0.1s" data-wow-duration="1500ms">
        <div className="footer-widget footer-widget--links">
          <h2 className="footer-widget__title">Company</h2>{/* /.footer-widget__title */}
          <ul className="footer-widget__links">
            <li><a href="about.html">About Us</a></li>
            <li><a href="https://bracketweb.com/trevlo-html/blog.html">Community Blog</a></li>
            <li><a href="https://bracketweb.com/trevlo-html/destinations.html">Destinations</a></li>
            <li><a href="our-guide.html">Meet the Guide</a></li>
            <li><a href="contact.html">Contact Now</a></li>
          </ul>{/* /.footer-widget__links */}
        </div>{/* /.footer-widget */}
      </div>{/* /.col-md-6 */}
      <div className="col-lg-3 col-md-4 col-sm-6 col-xl-2 wow animated fadeInUp" data-wow-delay="0.3s" data-wow-duration="1500ms">
        <div className="footer-widget footer-widget--links">
          <h2 className="footer-widget__title">Explore</h2>{/* /.footer-widget__title */}
          <ul className="footer-widget__links">
            <li><a href="login.html">Account</a></li>
            <li><a href="tour-listing-1.html">Tour Listings</a></li>
            <li><a href="contact.html">Privacy Policy</a></li>
            <li><a href="faq.html">Help</a></li>
          </ul>{/* /.footer-widget__links */}
        </div>{/* /.footer-widget */}
      </div>{/* /.col-md-6 */}
      <div className="col-lg-6 col-md-4 col-xl-4 wow animated fadeInUp" data-wow-delay="0.5s" data-wow-duration="1500ms">
        <div className="footer-widget footer-widget--contact">
          <h2 className="footer-widget__title">Contact</h2>{/* /.footer-widget__title */}
          <p className="footer-widget__address">Near Jazz Franchise Johar Town,Lahore, Pakistan</p>
          <ul className="footer-widget__info">
            <li> <span className="icon-phone-1" /> <a href="tel:(+92)3224070193">+923224070193</a></li>
            <li> <span className="icon-envelope" /> <a href="mailto:michael.mitc@example.com">pakqurtuba@hotmail.com</a></li>
          </ul>{/* /.footer-widget__info */}
        </div>{/* /.footer-widget */}
      </div>{/* /.col-md-6 */}
      <div className="col-lg-7 col-xl-4 wow animated fadeInUp" data-wow-delay="0.7s" data-wow-duration="1500ms">
        <div className="footer-widget footer-widget--newsletter">
          <h2 className="footer-widget__title">Newsletter</h2>{/* /.footer-widget__title */}
          <form action="#" data-url="MAILCHIMP_FORM_URL" className="footer-widget__newsletter-form mc-form">
            <input type="email" name="EMAIL" id="footer-widget-newsletter-input" placeholder="Email Address" className="footer-widget__newsletter-input" />
            <button type="submit" className="footer-widget__newsletter-btn trevlo-btn trevlo-btn--base-three"><span>Subscribe</span></button>
          </form>{/* /.footer-widget__newsletter-form */}
          <div className="mc-form__response" />{/* /.mc-form__response */}
        </div>{/* /.footer-widget */}
      </div>{/* /.col-md-6 */}
    </div>{/* /.row */}
  </div>{/* /.container */}
  <div className="main-footer__bottom">
    <div className="container">
      <div className="main-footer__bottom-inner">
        <p className="main-footer__copyright">
          © Copyright <span className="dynamic-year" /> by Ammar Sami
        </p>
      </div>{/* /.main-footer__inner */}
    </div>{/* /.container */}
  </div>{/* /.main-footer__bottom */}
</footer>{/* /.main-footer */}

    </div>
  )
}

export default Footer
