import React from 'react'

function Mobilenav() {
  return (
    <div>
    <div>
  <div className="mobile-nav__wrapper">
    <div className="mobile-nav__overlay mobile-nav__toggler" />
    {/* /.mobile-nav__overlay */}
    <div className="mobile-nav__content">
      <span className="mobile-nav__close mobile-nav__toggler"><i className="fa fa-times" /></span>
      <div className="logo-box">
      <img src="assets/images/logo.png" alt="Trevlo HTML" width={100}/>
              <span className='text-dark fs-5 fw-semibold text-white'>PAK QURTUBA</span>      </div>
      {/* /.logo-box */}
      <div className="mobile-nav__container" />
      {/* /.mobile-nav__container */}
      <ul className="mobile-nav__contact list-unstyled">
        <li>
          <i className="fa fa-envelope" />
          <a href="mailto:needhelp@trevlo.com">pakqurtuba@gmail.com</a>
        </li>
        <li>
          <i className="fa fa-phone-alt" />
          <a href="tel:(303)5550105">+923224070193</a>
        </li>
      </ul>{/* /.mobile-nav__contact */}
      <div className="mobile-nav__social">
        <a href="https://twitter.com">
          <i className="fab fa-twitter" aria-hidden="true" />
          <span className="sr-only">Twitter</span>
        </a>
        <a href="https://facebook.com">
          <i className="fab fa-facebook" aria-hidden="true" />
          <span className="sr-only">Facebook</span>
        </a>
        <a href="https://pinterest.com">
          <i className="fab fa-pinterest-p" aria-hidden="true" />
          <span className="sr-only">Pinterest</span>
        </a>
        <a href="https://instagram.com">
          <i className="fab fa-instagram" aria-hidden="true" />
          <span className="sr-only">Instagram</span>
        </a>
      </div>{/* /.mobile-nav__social */}
    </div>
    {/* /.mobile-nav__content */}
  </div>
  {/* /.mobile-nav__wrapper */}
  <div className="search-popup">
    <div className="search-popup__overlay search-toggler" />
    {/* /.search-popup__overlay */}
    <div className="search-popup__content">
      <form role="search" method="get" className="search-popup__form" action="#">
        <input type="text" id="search" placeholder="Search Here..." />
        <button type="submit" aria-label="search submit" className="search-popup__btn trevlo-btn trevlo-btn--base">
          <span className="icon-search" />
        </button>
      </form>
    </div>
    {/* /.search-popup__content */}
  </div>
  {/* /.search-popup */}
  <a href="#" data-target="html" className="scroll-to-target scroll-to-top">
    <span className="scroll-to-top__text">back top</span>
    <span className="scroll-to-top__wrapper"><span className="scroll-to-top__inner" /></span>
  </a>
</div>

    </div>
  )
}

export default Mobilenav
