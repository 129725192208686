import React from 'react'

function WhyOurCompany() {
  return (
    <div>
    <section className="benefit-one" style={{backgroundColor:"#241C26"}}>
  <div className="benefit-one__bg" />{/* /.benefit-bg */}
  <div className="container">
    <div className="row">
      <div className="col-lg-5">
        <div className="benefit-one__content">
          <div className="sec-title text-left">
            <p className="sec-title__tagline">GET TO KNOW US</p>{/* /.sec-title__tagline */}
            <h2 className="sec-title__title text-white">Why You Should Choose Our Company</h2>{/* /.sec-title__title */}
          </div>{/* /.sec-title */}{/* /.sec-title */}
          <h5 className="benefit-one__content__heading">Best ways to enjoy adventures</h5>
          <p className="benefit-one__content__text">
            There are many variations of passages of Lorem Ipsum simply free text available, but the majority. 
          </p>
          <div className="benefit-one__box-wrapper" style={{backgroundColor:"#4A3E51"}}>
            <div className="benefit-one__box" >
              <div className="benefit-one__box__icon"><span className="icon-airplane-1" /></div>
              <h3 className="benefit-one__box__title" style={{color:"#FF5956"}}>Professional &amp; Certified</h3>
            </div>{/* /.benefit-box */}
            <div className="benefit-one__box">
              <div className="benefit-one__box__icon" ><span className="icon-ticket-1" /></div>
              <h3 className="benefit-one__box__title" style={{color:"#FF5956"}}>Get Instant Tour Bookings</h3>
            </div>{/* /.benefit-box */}
          </div>
          <a href="about.html" className="trevlo-btn"><span>Discover More</span></a>{/* /.button */}
        </div>
      </div>
      <div className="col-lg-7 wow fadeInRight" data-wow-delay="200ms">
        <div className="benefit-one__image"><img src="./assets/images/resources/baku.jpg" alt="trevlo" width={750} height={450} loading='lazy'/></div>
        <div className="benefit-one__counter">
          <div className="benefit-one__counter__icon"><span className="icon-satisfied" /></div>{/* /.counter__icon */}
          <div className="benefit-one__counter__number count-box"><span className="count-text" data-stop={3800} data-speed={1500} /> </div>{/* /.counter__number */}
          <p className="benefit-one__counter__title text-white">Satisfied Customers</p>{/* /.counter__title */}
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}

export default WhyOurCompany
