import React from 'react'

function DestinationList() {
  return (
    <div>
      {/* Destination Start */}
<section className="destination-one" style={{backgroundColor:"#241C26"}}>
  <div className="container">
    <div className="destination-page__row row">
      <div className="col-xl-3 col-md-6 wow animated fadeInUp" data-wow-delay="0.1s" data-wow-duration="1500ms">
        <div className="sec-title text-left">
          <p className="sec-title__tagline">Destination list</p>{/* /.sec-title__tagline */}
          <h2 className="sec-title__title" style={{color:"white"}}>Explore the Beautiful Places Around the World</h2>{/* /.sec-title__title */}
        </div>{/* /.sec-title */}{/* /.sec-title */}
      </div>
      <div className="col-xl-3 col-md-6 wow animated fadeInUp" data-wow-delay="0.3s" data-wow-duration="1500ms">
        <div className="destination-one__card">
          <div className="destination-one__card-img-box destination-one__card-img-box--round">
            <img src="assets/images/destination/destination-1-2.jpg" alt="destination" className="destination-one__card-img destination-one__card-img--round" />
            <div className="destination-one__card-overlay destination-one__card-overlay--round">
              <div className="destination-one__card-content destination-one__card-content--round">
                <a href="destination-details-right.html" className="destination-one__card-btn destination-one__card-btn--round trevlo-btn trevlo-btn--base trevlo-btn--base-three"><span>8 TOURS</span></a>
                <h4 className="destination-one__card-title"><a href="destination-details-right.html">Spain</a></h4>
              </div>{/* /.destination-one__card-content destination-one__card-content--round */}
            </div>{/* /.destination-one__card-overlay destination-one__card-overlay--round */}
          </div>{/* /.destination-one__card-img-box */}
        </div>{/* /.destination-one__card */}
      </div>
      <div className="col-xl-6 col-lg-8 wow animated fadeInUp" data-wow-delay="0.5s" data-wow-duration="1500ms">
        <div className="destination-one__card">
          <div className="destination-one__card-img-box destination-one__card-img-box--round">
            <img src="assets/images/destination/destination-1-3.jpg" alt="destination" className="destination-one__card-img destination-one__card-img--round" />
            <div className="destination-one__card-overlay destination-one__card-overlay--round">
              <div className="destination-one__card-content destination-one__card-content--round">
                <a href="destination-details-right.html" className="destination-one__card-btn destination-one__card-btn--round trevlo-btn trevlo-btn--base trevlo-btn--base-three"><span>7 TOURS</span></a>
                <h4 className="destination-one__card-title"><a href="destination-details-right.html">Thailand</a></h4>
              </div>{/* /.destination-one__card-content destination-one__card-content--round */}
            </div>{/* /.destination-one__card-overlay destination-one__card-overlay--round */}
          </div>{/* /.destination-one__card-img-box */}
        </div>{/* /.destination-one__card */}
      </div>
      <div className="col-xl-3 col-lg-4 col-md-6 wow animated fadeInUp" data-wow-delay="0.1s" data-wow-duration="1500ms">
        <div className="destination-one__card">
          <div className="destination-one__card-img-box destination-one__card-img-box--circle">
            <img src="assets/images/destination/destination-1-4.jpg" alt="destination" className="destination-one__card-img destination-one__card-img--circle" />
            <div className="destination-one__card-overlay destination-one__card-overlay--circle">
              <div className="destination-one__card-content destination-one__card-content--circle">
                <a href="destination-details-right.html" className="destination-one__card-btn destination-one__card-btn--circle trevlo-btn trevlo-btn--base trevlo-btn--base-three"><span>8 TOURS</span></a>
                <h4 className="destination-one__card-title"><a href="destination-details-right.html">Dubai</a></h4>
              </div>{/* /.destination-one__card-content destination-one__card-content--circle */}
            </div>{/* /.destination-one__card-overlay destination-one__card-overlay--circle */}
          </div>{/* /.destination-one__card-img-box */}
        </div>{/* /.destination-one__card */}
      </div>
      <div className="col-xl-3 col-lg-4 col-md-6 wow animated fadeInUp" data-wow-delay="0.3s" data-wow-duration="1500ms">
        <div className="destination-one__card">
          <div className="destination-one__card-img-box destination-one__card-img-box--round">
            <img src="assets/images/destination/destination-1-5.jpg" alt="destination" className="destination-one__card-img destination-one__card-img--round" />
            <div className="destination-one__card-overlay destination-one__card-overlay--round">
              <div className="destination-one__card-content destination-one__card-content--round">
                <a href="destination-details-right.html" className="destination-one__card-btn destination-one__card-btn--round trevlo-btn trevlo-btn--base trevlo-btn--base-three"><span>13 TOURS</span></a>
                <h4 className="destination-one__card-title"><a href="destination-details-right.html">Australia</a></h4>
              </div>{/* /.destination-one__card-content destination-one__card-content--round */}
            </div>{/* /.destination-one__card-overlay destination-one__card-overlay--round */}
          </div>{/* /.destination-one__card-img-box */}
        </div>{/* /.destination-one__card */}
      </div>
      <div className="col-xl-3 col-lg-4 col-md-6 wow animated fadeInUp" data-wow-delay="0.5s" data-wow-duration="1500ms">
        <div className="destination-one__card">
          <div className="destination-one__card-img-box destination-one__card-img-box--circle">
            <img src="assets/images/destination/destination-1-7.jpg" alt="destination" className="destination-one__card-img destination-one__card-img--circle" />
            <div className="destination-one__card-overlay destination-one__card-overlay--circle">
              <div className="destination-one__card-content destination-one__card-content--circle">
                <a href="destination-details-right.html" className="destination-one__card-btn destination-one__card-btn--circle trevlo-btn trevlo-btn--base trevlo-btn--base-three"><span>2 TOURS</span></a>
                <h4 className="destination-one__card-title"><a href="destination-details-right.html">Italy</a></h4>
              </div>{/* /.destination-one__card-content destination-one__card-content--circle */}
            </div>{/* /.destination-one__card-overlay destination-one__card-overlay--circle */}
          </div>{/* /.destination-one__card-img-box */}
        </div>{/* /.destination-one__card */}
      </div>
      <div className="col-xl-3 col-lg-4 col-md-6 wow animated fadeInUp" data-wow-delay="0.7s" data-wow-duration="1500ms">
        <div className="destination-one__offer">
          <h5 className="destination-one__offer__sub-title">20% Off</h5>
          <h2 className="destination-one__offer__title">Summer <br />Hotest Deals</h2>
          <a href="destination-1.html" className="trevlo-btn"><span>View Deals</span></a>
        </div>{/* /.offer- */}
      </div>
    </div>{/* /.row */}
  </div>
</section>
{/* Destination End */}

    </div>
  )
}

export default DestinationList
