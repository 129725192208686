import React from 'react'

function BookingOffer() {
  return (
    <>
  {/* Offer One Start */}
  <section className="offer-one" style={{backgroundImage: 'url("./assets/images/backgrounds/offer-1-bg.png")'}}>
    <div className="container">
      <div className="row">
        <div className="col-xl-5 col-lg-6">
          <div className="offer-one__content sec-title">
            <p className="offer-one__top-title">Special offer for you</p>
            <h2 className="offer-one__heading sec-title__heading">Start your Journey with a Single Click</h2>
            <p className="offer-one__text">There are many variations of passages of Lorem Ipsum available, but the majority have</p>
            <div className="offer-one__btn-box wow animated fadeInUp" data-wow-delay="0.1s" data-wow-duration="1500ms">
              <a href="tour-listing-side-filter-right.html" className="offer-one__btn trevlo-btn trevlo-btn--primary"><span>Start Booking</span></a>
            </div>{/* /.offer-one__btn-box */}
          </div>{/* /.offer-one__content */}
        </div>{/* /.col-xl-5 col-lg-6 */}
        <div className="col-xl-7 col-lg-6 wow animated fadeInRight" data-wow-delay="0.1s" data-wow-duration="1500ms" >
          <div className="offer-one__img-box" >
            <div className="offer-one__inner-img-box">
              <img src="./assets/images/offer/bg3.jpg" alt="offer" className="offer-one__img-one" />
              <img src="./assets/images/offer/offerr.jpg" alt="offer" className="offer-one__img-two" />
              <img src="./assets/images/offer/offer-1-3.png" alt="offer" className="offer-one__img-three" />
            </div>{/* /.offer-one__inner-img-box */}
          </div>{/* /.offer-one__img-box */}
        </div>{/* /.col-xl-7 col-lg-6 */}
      </div>{/* /.row */}
    </div>{/* /.container */}
    <div className="offer-one__shape-one trevlo-splax" data-para-options="{ &quot;orientation&quot;: &quot;left&quot;, &quot;scale&quot;: 1.5, &quot;overflow&quot;: true }" style={{backgroundImage: 'url("./assets/images/shapes/offer-shape-1.png")'}} />{/* /.bg */}
    <div className="offer-one__shape-two trevlo-splax" data-para-options="{ &quot;orientation&quot;: &quot;right&quot;, &quot;scale&quot;: 1.5, &quot;overflow&quot;: true }" style={{backgroundImage: 'url("./assets/images/shapes/offer-shape-2.png")'}} />{/* /.bg */}
    <div className="offer-one__bottom-bg" style={{backgroundImage: 'url("./assets/images/offer/offer-1-4.png")'}} />{/* /.bg */}
  </section>
  {/* Offer End Start */}

    </>
  )
}

export default BookingOffer
