import React from 'react'

function TourTypes() {
  return (
    <div>
    <section className="why-choose-one">
  <div className="why-choose-one__bg" style={{backgroundImage: 'url(assets/images/backgrounds/why-choose-bg-1.jpg)'}} />
  {/* /.why-choose__bg */}
  <div className="container">
    <div className="row">
      <div className="col-xl-5 col-lg-6 wow fadeInUp" data-wow-delay="200ms">
        <div className="sec-title text-left">
          <p className="sec-title__tagline">Are you ready to travel</p>{/* /.sec-title__tagline */}
          <h2 className="sec-title__title">Choose Our Tour Types<br /> &amp; Enjoy Now</h2>{/* /.sec-title__title */}
        </div>{/* /.sec-title */}{/* /.sec-title */}
      </div>
      <div className="col-xl-7 col-lg-6 wow fadeInUp" data-wow-delay="200ms">
        <p className="why-choose-one__text">
          There are many variations of passages of Lorem Ipsum available, but the majority have 
          suffered alteradution in some form by injected humour,  some form
        </p>
      </div>
    </div>
    <div className="row gutter-y-30">
      <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="100ms">
        <div className="why-choose-one__box">
          <div className="why-choose-one__box__bg" style={{backgroundImage: 'url(assets/images/backgrounds/why-choose-bg-1-1.png)'}} />
          <div className="why-choose-one__box__icon"><span className="icon-wildlife" /></div>
          <h3 className="why-choose-one__box__title">Wildlife</h3>
        </div>{/* /.why-choose-box */}
      </div>
      <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="200ms">
        <div className="why-choose-one__box">
          <div className="why-choose-one__box__bg" style={{backgroundImage: 'url(assets/images/backgrounds/why-choose-bg-1-2.png)'}} />
          <div className="why-choose-one__box__icon"><span className="icon-paragliding-1" /></div>
          <h3 className="why-choose-one__box__title">Paragliding Tours</h3>
        </div>{/* /.why-choose-box */}
      </div>
      <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="300ms">
        <div className="why-choose-one__box">
          <div className="why-choose-one__box__bg" style={{backgroundImage: 'url(assets/images/backgrounds/why-choose-bg-1-3.png)'}} />
          <div className="why-choose-one__box__icon"><span className="icon-hiking-1" /></div>
          <h3 className="why-choose-one__box__title">Adventure Tours</h3>
        </div>{/* /.why-choose-box */}
      </div>
      <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="400ms">
        <div className="why-choose-one__box">
          <div className="why-choose-one__box__bg" style={{backgroundImage: 'url(assets/images/backgrounds/why-choose-bg-1-4.png)'}} />
          <div className="why-choose-one__box__icon"><span className="icon-hang-gliding-1" /></div>
          <h3 className="why-choose-one__box__title">Hang Gliding Tours</h3>
        </div>{/* /.why-choose-box */}
      </div>
    </div>
  </div>{/* /.container */}
</section>{/* /.why-choose-one */}

    </div>
  )
}

export default TourTypes
