import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CombineComp from './pages/CombineComp';
import Contactus from './pages/Contactus';
import Aboutus from './pages/Aboutus';
import HajjForm from './pages/HajjForm';
import MakkahHotels from './pages/MakkahHotels';
import MadinaHotels from './pages/MadinaHotels';
import Hotels from './pages/Hotels';
import Gallery from './pages/Gallery';
import Umrah from './pages/Umrah';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<CombineComp />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/hotels" element={<Hotels />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/umrah" element={<Umrah />} />
        <Route path="/hajjregistration" element={<HajjForm />} />
        <Route path="/makkahhotels" element={<MakkahHotels />} />
        <Route path="/madinahotels" element={<MadinaHotels />} />
      </Routes>
    </Router>
  );
}

export default App;
