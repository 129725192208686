import React from 'react'

function Testimonials() {
  return (
    <div>
     {/* Testimonial One Start */}
<section className="testimonial-one testimonial-one--home" style={{backgroundColor:"#241C26"}}>
  <div className="testimonial-one__bg" style={{backgroundImage: 'url(./assets/images/shapes/testimonial-1-bg-2-dark.png)'}}>
  </div>{/* /.testimonial-one__bg */}
  <div className="container">
    <div className="sec-title text-center">
      <p className="sec-title__tagline">Testimonial</p>{/* /.sec-title__tagline */}
      <h2 className="sec-title__title text-white">What Our Customers are<br /> Saying?</h2>{/* /.sec-title__title */}
    </div>{/* /.sec-title */}{/* /.sec-title */}
  </div>{/* /.container */}
  <div className="container">
    <div className="testimonial-one__carousel trevlo-owl__carousel trevlo-owl__carousel--basic-nav trevlo-owl__carousel--with-shadow owl-theme owl-carousel" data-owl-options="{
      &quot;items&quot;: 3,
      &quot;margin&quot;: 42,
      &quot;smartSpeed&quot;: 700,
      &quot;loop&quot;:false,
      &quot;autoplay&quot;: 6000,
      &quot;nav&quot;:false,
      &quot;dots&quot;:true,
      &quot;navText&quot;: [&quot;<span class=\&quot;fa fa-angle-left\&quot;></span>&quot;,&quot;<span class=\&quot;fa fa-angle-right\&quot;></span>&quot;],
      &quot;responsive&quot;:{
          &quot;0&quot;:{
              &quot;items&quot;: 1
          },
          &quot;768&quot;:{
              &quot;items&quot;: 2
          },
          &quot;1200&quot;:{
              &quot;items&quot;: 3,
              &quot;dots&quot;: false
          }
      }
      }">
      <div className="item" >
        <div className="testimonials-card" >
          <div className="testimonials-card__image">
            <img src="./assets/images/testimonial/testimonial-1-1.jpg" alt="Jacob Jones" />
          </div>
          <div className="testimonials-card__meta" >
            <h5 className="testimonials-card__meta__name">Jacob Jones</h5>
            <p className="testimonials-card__meta__designation">Tourist</p>
          </div>{/* /.testimonials-card__meta */}
          <div className="testimonials-card__ratings">
            <span className="icon-star" />
            <span className="icon-star" />
            <span className="icon-star" />
            <span className="icon-star" />
            <span className="icon-star" />
          </div>{/* /.testimonials-card__ratings */}
          <div className="testimonials-card__quote">Flexible Classes refers to the process of acquiring is knowledge free or skills through the use of process the digital technologies and the internet. Flexible Classes</div>{/* /.testimonials-card__quote */}
        </div>{/* /.testimonials-card */}
      </div>{/* /.owl-slide-item*/}
      <div className="item">
        <div className="testimonials-card">
          <div className="testimonials-card__image">
            <img src="./assets/images/testimonial/testimonial-1-2.jpg" alt="Robert Fox" />
          </div>
          <div className="testimonials-card__meta">
            <h5 className="testimonials-card__meta__name">Robert Fox</h5>
            <p className="testimonials-card__meta__designation">Tourist</p>
          </div>{/* /.testimonials-card__meta */}
          <div className="testimonials-card__ratings">
            <span className="icon-star" />
            <span className="icon-star" />
            <span className="icon-star" />
            <span className="icon-star" />
            <span className="icon-star" />
          </div>{/* /.testimonials-card__ratings */}
          <div className="testimonials-card__quote">Flexible Classes refers to the process of acquiring is knowledge free or skills through the use of process the digital technologies and the internet. Flexible Classes</div>{/* /.testimonials-card__quote */}
        </div>{/* /.testimonials-card */}
      </div>{/* /.owl-slide-item*/}
      <div className="item">
        <div className="testimonials-card">
          <div className="testimonials-card__image">
            <img src="./assets/images/testimonial/testimonial-1-3.jpg" alt="Guy Hawkins" />
          </div>
          <div className="testimonials-card__meta">
            <h5 className="testimonials-card__meta__name">Guy Hawkins</h5>
            <p className="testimonials-card__meta__designation">Tourist</p>
          </div>{/* /.testimonials-card__meta */}
          <div className="testimonials-card__ratings">
            <span className="icon-star" />
            <span className="icon-star" />
            <span className="icon-star" />
            <span className="icon-star" />
            <span className="icon-star" />
          </div>{/* /.testimonials-card__ratings */}
          <div className="testimonials-card__quote">Flexible Classes refers to the process of acquiring is knowledge free or skills through the use of process the digital technologies and the internet. Flexible Classes</div>{/* /.testimonials-card__quote */}
        </div>{/* /.testimonials-card */}
      </div>{/* /.owl-slide-item*/}
      <div className="item">
        <div className="testimonials-card">
          <div className="testimonials-card__image">
            <img src="./assets/images/testimonial/testimonial-1-1.jpg" alt="Jacob Jones" />
          </div>
          <div className="testimonials-card__meta">
            <h5 className="testimonials-card__meta__name">Jacob Jones</h5>
            <p className="testimonials-card__meta__designation">Tourist</p>
          </div>{/* /.testimonials-card__meta */}
          <div className="testimonials-card__ratings">
            <span className="icon-star" />
            <span className="icon-star" />
            <span className="icon-star" />
            <span className="icon-star" />
            <span className="icon-star" />
          </div>{/* /.testimonials-card__ratings */}
          <div className="testimonials-card__quote">Flexible Classes refers to the process of acquiring is knowledge free or skills through the use of process the digital technologies and the internet. Flexible Classes</div>{/* /.testimonials-card__quote */}
        </div>{/* /.testimonials-card */}
      </div>{/* /.owl-slide-item*/}
      <div className="item">
        <div className="testimonials-card">
          <div className="testimonials-card__image">
            <img src="./assets/images/testimonial/testimonial-1-2.jpg" alt="Robert Fox" />
          </div>
          <div className="testimonials-card__meta">
            <h5 className="testimonials-card__meta__name">Robert Fox</h5>
            <p className="testimonials-card__meta__designation">Tourist</p>
          </div>{/* /.testimonials-card__meta */}
          <div className="testimonials-card__ratings">
            <span className="icon-star" />
            <span className="icon-star" />
            <span className="icon-star" />
            <span className="icon-star" />
            <span className="icon-star" />
          </div>{/* /.testimonials-card__ratings */}
          <div className="testimonials-card__quote">Flexible Classes refers to the process of acquiring is knowledge free or skills through the use of process the digital technologies and the internet. Flexible Classes</div>{/* /.testimonials-card__quote */}
        </div>{/* /.testimonials-card */}
      </div>{/* /.owl-slide-item*/}
      <div className="item">
        <div className="testimonials-card">
          <div className="testimonials-card__image">
            <img src="./assets/images/testimonial/testimonial-1-3.jpg" alt="Guy Hawkins" />
          </div>
          <div className="testimonials-card__meta">
            <h5 className="testimonials-card__meta__name">Guy Hawkins</h5>
            <p className="testimonials-card__meta__designation">Tourist</p>
          </div>{/* /.testimonials-card__meta */}
          <div className="testimonials-card__ratings">
            <span className="icon-star" />
            <span className="icon-star" />
            <span className="icon-star" />
            <span className="icon-star" />
            <span className="icon-star" />
          </div>{/* /.testimonials-card__ratings */}
          <div className="testimonials-card__quote">Flexible Classes refers to the process of acquiring is knowledge free or skills through the use of process the digital technologies and the internet. Flexible Classes</div>{/* /.testimonials-card__quote */}
        </div>{/* /.testimonials-card */}
      </div>{/* /.owl-slide-item*/}
    </div>{/* /.thm-owl__slider */}
  </div>{/* /.container */}
</section>

    </div>
  )
}

export default Testimonials
