import React from "react";
import { UilPlaneDeparture } from "@iconscout/react-unicons";
import { UilGlobe } from "@iconscout/react-unicons";
function About() {
  return (
    <>
      {/* About Start */}
      <section className="about-one" style={{ backgroundColor: "#241C26" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 wow fadeInLeft"
              data-wow-delay="200ms"
            >
              <div className="about-one__image">
                <div className="row">
                  <div className="col-md-5">
                    <div
                      className="about-one__image__one trevlo-tilt"
                      data-tilt-options='{ "glare": false, "maxGlare": 0, "maxTilt": 3, "speed": 700, "scale": 1 }'
                    >
                      <img
                        src="./assets/images/resources/saudi.jpg"
                        alt="trevlo"
                      />
                      <img
                        src="./assets/images/resources/madina.jpg"
                        alt="trevlo"
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 178 30"
                      >
                        <path d="M177.276 2.12957C155.784 21.96 90.3733 49.5857 0.667034 1.44487" />
                      </svg>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="about-one__image__two">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 163 75"
                      >
                        <path d="M1.02818 74.4809C12.3872 47.5342 60.5019 -4.68383 162.089 2.01835" />
                      </svg>
                      <div
                        className="trevlo-tilt"
                        data-tilt-options='{ "glare": false, "maxGlare": 0, "maxTilt": 5, "speed": 700, "scale": 1 }'
                      >
                        <img
                          src="./assets/images/resources/makkahone.jpg"
                          alt="trevlo"
                          loading="lazy"
                        />
                      </div>
                      <div className="about-one__image__two__shape">
                        <img
                          src="./assets/images/shapes/about-1-shape-3.png"
                          alt="trevlo"
                        />
                      </div>
                      <div
                        className="about-one__counter"
                        style={{
                          backgroundImage:
                            "url(./assets/images/shapes/about-1-shape-4.png)",
                        }}
                      >
                        <div className="about-one__counter__number count-box">
                          <span
                            className="count-text"
                            data-stop={30}
                            data-speed={1500}
                          />
                          %
                        </div>
                        {/* /.counter__number */}
                        <p className="about-one__counter__title">Discount</p>
                        {/* /.counter__title */}
                      </div>
                      {/* /.discount */}
                    </div>
                  </div>
                </div>
              </div>
              {/* /.about-one__image */}
              <div className="about-one__shape-one">
                <img
                  src="./assets/images/shapes/about-1-shape-1-dark.png"
                  alt="trevlo"
                />
              </div>
              {/* /.about-one__shape */}
              <div className="about-one__shape-two">
                <img
                  src="./assets/images/shapes/about-1-shape-2-dark.png"
                  alt="trevlo"
                />
              </div>
              {/* /.about-one__shape */}
            </div>
            {/* /.col-xl-6 col-lg-6 */}
            <div
              className="col-xl-6 col-lg-6 wow fadeInRight"
              data-wow-delay="200ms"
            >
              <div className="about-one__content">
                <div className="sec-title text-left">
                  <p className="sec-title__tagline text-white">
                    GET TO KNOW US
                  </p>
                  {/* /.sec-title__tagline */}
                  <h2 className="sec-title__title text-white">
                    Experience the World with Our Company
                  </h2>
                  {/* /.sec-title__title */}
                </div>
                {/* /.sec-title */}
                {/* /.sec-title */}
                <p className="about-one__content__text text-white">
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                </p>
                <div className="about-one__box">
                  <div className="about-one__box__icon">
                    <span>
                      {" "}
                      <UilPlaneDeparture size="45" color="white" />
                    </span>
                  </div>
                  <h3 className="about-one__box__title text-white">
                    Safety First
                    <br /> Always
                  </h3>
                  <p className="about-one__box__text ">
                    Lorem Ipsum is simply dummy text of
                    <br /> the printing.
                  </p>
                </div>
                {/* /.about-box */}
                <div className="about-one__box">
                  <div className="about-one__box__icon">
                    <span>
                      <UilGlobe size="48" color="white" />
                    </span>
                  </div>
                  <h3 className="about-one__box__title text-white">
                    Friendly
                    <br /> Guide
                  </h3>
                  <p className="about-one__box__text">
                    Lorem Ipsum is simply dummy text of
                    <br /> the printing.
                  </p>
                </div>
                {/* /.about-box */}
                <a href="about.html" className="trevlo-btn">
                  <span>Discover More</span>
                </a>
              </div>
              {/* /.about-four__content */}
            </div>
            {/* /.col-xl-6 col-lg-6 */}
          </div>
          {/* /.row */}
        </div>
      </section>
    </>
  );
}

export default About;
