import React from "react";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import Mobilenav from "../components/common/Mobilenav";

function Hotels() {
  return (
    <div>
      <div class="page-wrapper">
        <Navbar />
        <section className="page-header">
          <div className="page-header__bg" />
          {/* /.page-header__bg */}
          <div className="container">
            <h2
              className="page-header__title wow animated fadeInLeft"
              data-wow-delay="0s"
              data-wow-duration="1500ms"
            >
            Hotels
            </h2>
            <div className="page-header__breadcrumb-box">
              <ul className="trevlo-breadcrumb">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Hotels</li>
              </ul>
              {/* /.trevlo-breadcrumb */}
            </div>
            {/* /.page-header__breadcrumb-box */}
          </div>
          {/* /.container */}
        </section>
        {/* /.page-header */}
        {/* tab-section-start */}
        <section className="tab-one">
          <div
            className="tab-one__bg"
            style={{
              backgroundImage: "url(assets/images/shapes/tab-bg-1.png)",
            }}
          />
          <div className="container">
            <div className="sec-title text-left">
              <p className="sec-title__tagline">Details</p>
              {/* /.sec-title__tagline */}
              <h2 className="sec-title__title">Makkah Hotels</h2>
              {/* /.sec-title__title */}
            </div>
            {/* /.sec-title */}
            <div className="row tabs-box">
              <div className="col-xl-5 col-lg-5">
                <ul className="list-unstyled tab-buttons tab-one__list">
                  <li data-tab="#araekalkhalood" className="tab-btn">
                    <span className="icon-tent-1" />
                    Araek AL Khalood
                  </li>
                  <li data-tab="#alfajaralbadia" className="tab-btn active-btn">
                    <span className="icon-traveling-1" />
                    Al Fajar Albadia
                  </li>
                  <li data-tab="#almasabadar" className="tab-btn">
                    <span className="icon-mountain-bike-1" />
                    Al Masa Badar
                  </li>
                  <li data-tab="#matharaljawar" className="tab-btn">
                    <span className="icon-world-1" />
                    Mather Al Jawar
                  </li>
                  <li data-tab="#saifmajad" className="tab-btn">
                    <span className="icon-fishing-net-1" />
                    Saif Al Majad
                  </li>
                  <li data-tab="#nawaralshams" className="tab-btn">
                    <span className="icon-paragliding-1" />
                    Nawar Al Shams
                  </li>
                </ul>
                {/* /.list-unstyledf */}
              </div>
              <div className="col-xl-7 col-lg-7">
                <div className="tabs-content">
                  <div className="tab fadeInUp animated" id="araekalkhalood">
                    <div className="tab-one__content">
                      <div className="tab-one__content__img">
                        <img
                          src="assets/images/makkahhotels/araek.jpg"
                          alt="travel"
                        />
                        <div className="tab-one__content__icon">
                          <span className="icon-tent-1" />
                        </div>
                      </div>
                      <h4 className="tab-one__content__title">
                        Araek Al Khalood
                      </h4>
                      <p className="tab-one__content__text">
                        Located within 3.7 miles of Masjid Al Haram and 7.6
                        miles of Hira Cave, ARAEK AL KHLOOD HOTEL features rooms
                        in Makkah. Each accommodation at the 3-star hotel has
                        mountain views and free WiFi. There is a restaurant
                        serving Indian cuisine, and free private parking is
                        available.
                      </p>
                      <ul className="tab-one__content__list">
                        <li>
                          <span className="far fa-check-circle" />
                          24-hour front desk
                        </li>
                        <li>
                          <span className="far fa-check-circle" />
                          Shuttle Service
                        </li>
                      </ul>
                      <div className="clearfix" />
                    </div>
                  </div>
                  <div
                    className="tab fadeInUp animated active-tab"
                    id="alfajaralbadia"
                  >
                    <div className="tab-one__content">
                      <div className="tab-one__content__img">
                        <img
                          src="assets/images/makkahhotels/alfajaralbadia.jpeg"
                          alt="travel"
                        />
                        <div className="tab-one__content__icon">
                          <span className="icon-traveling-1" />
                        </div>
                      </div>
                      <h4 className="tab-one__content__title">
                        Al Fajar Albadia
                      </h4>
                      <p className="tab-one__content__text">
                        Set 1.7 km from Zamzam Well, this Mecca property is also
                        within 20 minutes' walk of the massive Masjid al-Haram
                        Mosque. Places of worship to visit within 5 minutes'
                        drive of the hotel include the Great Al Salam Gate
                        Mosque. The accommodation is situated within walking
                        distance of the cubical Kaaba Shrine, and Saptco bus
                        station lies merely 1 km away.
                      </p>
                      <ul className="tab-one__content__list">
                        <li>
                          <span className="far fa-check-circle" />
                          Cheap Budget Hotel
                        </li>
                        <li>
                          <span className="far fa-check-circle" />
                          Luxury Accomodation
                        </li>
                      </ul>
                      <div className="clearfix" />
                    </div>
                  </div>
                  <div className="tab fadeInUp animated" id="almasabadar">
                    <div className="tab-one__content">
                      <div className="tab-one__content__img">
                        <img
                          src="assets/images/makkahhotels/babaralmassa.jpg"
                          alt="travel"
                        />
                        <div className="tab-one__content__icon">
                          <span className="icon-mountain-bike-1" />
                        </div>
                      </div>
                      <h4 className="tab-one__content__title">Al Masa Badar</h4>
                      <p className="tab-one__content__text">
                        This Mecca property is also a 10-minute drive from such
                        natural sights as Ghar Thowr. The 3-star hotel is placed
                        at a moderate distance from Bab Al Umrah, and Saptco bus
                        station is a 15-minute walk away.
                      </p>
                      <ul className="tab-one__content__list">
                        <li>
                          <span className="far fa-check-circle" />
                          24/7 Front Desk Service
                        </li>
                        <li>
                          <span className="far fa-check-circle" />
                          Luxury Accomodation
                        </li>
                      </ul>
                      <div className="clearfix" />
                    </div>
                  </div>
                  <div className="tab fadeInUp animated" id="matharaljawar">
                    <div className="tab-one__content">
                      <div className="tab-one__content__img">
                        <img
                          src="assets/images/makkahhotels/matheraljawar.jpeg"
                          alt="travel"
                        />
                        <div className="tab-one__content__icon">
                          <span className="icon-world-1" />
                        </div>
                      </div>
                      <h4 className="tab-one__content__title">
                        Mather Al Jawar
                      </h4>
                      <p className="tab-one__content__text">
                        Maather Al Jewar Hotel Mecca features 497 rooms and lies
                        approximately 20 minutes' walk from the massive Masjid
                        al-Haram Mosque.This Mecca property is also a 10-minute
                        drive from such natural sights as Ghar Thowr. The 3-star
                        hotel is placed at a moderate distance from Bab Al
                        Umrah, and Saptco bus station is a 15-minute walk away.
                      </p>
                      <ul className="tab-one__content__list">
                        <li>
                          <span className="far fa-check-circle" />
                          24/7 Service
                        </li>
                        <li>
                          <span className="far fa-check-circle" />
                          Luxury Accomodation
                        </li>
                      </ul>
                      <div className="clearfix" />
                    </div>
                  </div>
                  <div className="tab fadeInUp animated" id="saifmajad">
                    <div className="tab-one__content">
                      <div className="tab-one__content__img">
                        <img
                          src="assets/images/makkahhotels/saifmajad.jpeg"
                          alt="travel"
                        />
                        <div className="tab-one__content__icon">
                          <span className="icon-fishing-net-1" />
                        </div>
                      </div>
                      <h4 className="tab-one__content__title">Saif Al Majad</h4>
                      <p className="tab-one__content__text">
                        Situated 20 minutes' walk from Kaaba, the 3-star Saif Al
                        Majd Hotel Mecca features WiFi in public areas, and a
                        private parking garage on site. Some rooms provide a
                        mini fridge bar and a coffee maker as well as a
                        flat-screen TV with satellite channels to help you enjoy
                        your stay. Guests can also use a tub and a walk-in
                        shower, along with such amenities as bath sheets.
                      </p>
                      <ul className="tab-one__content__list">
                        <li>
                          <span className="far fa-check-circle" />
                          24/7 Service
                        </li>
                        <li>
                          <span className="far fa-check-circle" />3 Star
                          Accomodation
                        </li>
                      </ul>
                      <div className="clearfix" />
                    </div>
                  </div>
                  <div className="tab fadeInUp animated" id="nawaralshams">
                    <div className="tab-one__content">
                      <div className="tab-one__content__img">
                        <img
                          src="assets/images/makkahhotels/nawaralshams.jpeg"
                          alt="travel"
                        />
                        <div className="tab-one__content__icon">
                          <span className="icon-paragliding-1" />
                        </div>
                      </div>
                      <h4 className="tab-one__content__title">
                        Nawar Al Shams
                      </h4>
                      <p className="tab-one__content__text">
                        Nawarat Shams (1) is after the Arij al-falah and it
                        takes 7-8 mins to reach to "Masjid Al haram". We are
                        pleased to offer a very good size of rooms in this hotel
                        , We are also proud to give you a good service and will
                        help you in any kinds of circumstances
                      </p>
                      <ul className="tab-one__content__list">
                        <li>
                          <span className="far fa-check-circle" />
                          24/7 Service
                        </li>
                        <li>
                          <span className="far fa-check-circle" />
                          Luxury Accomodation
                        </li>
                      </ul>
                      <div className="clearfix" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* /.client-carousel */}
        </section>
        {/* tab-section-end */}
        <section className="tab-one">
          <div
            className="tab-one__bg"
            style={{
              backgroundImage: "url(assets/images/shapes/tab-bg-1.png)",
            }}
          />
          <div className="container">
            <div className="sec-title text-left">
              <p className="sec-title__tagline">Details</p>
              {/* /.sec-title__tagline */}
              <h2 className="sec-title__title">Madina Hotels</h2>
              {/* /.sec-title__title */}
            </div>
            {/* /.sec-title */}
            <div className="row tabs-box">
              <div className="col-xl-5 col-lg-5">
                <ul className="list-unstyled tab-buttons tab-one__list">
                  <li data-tab="#alfalah" className="tab-btn">
                    <span className="icon-tent-1" />
                    Al Falah Suites
                  </li>
                  <li data-tab="#arjawan" className="tab-btn active-btn">
                    <span className="icon-traveling-1" />
                    Arjawan Al Madina
                  </li>
                  <li data-tab="#wardasafa" className="tab-btn">
                    <span className="icon-mountain-bike-1" />
                    Warda Safa
                  </li>
                  <li data-tab="#diyarsaatir" className="tab-btn">
                    <span className="icon-world-1" />
                    Diyar Saatir
                  </li>
                  <li data-tab="#marjan" className="tab-btn">
                    <span className="icon-fishing-net-1" />
                    Marjan International
                  </li>
                  <li data-tab="#alaws" className="tab-btn">
                    <span className="icon-paragliding-1" />
                    Al Aws
                  </li>
                </ul>
                {/* /.list-unstyledf */}
              </div>
              <div className="col-xl-7 col-lg-7">
                <div className="tabs-content">
                  <div className="tab fadeInUp animated" id="alfalah">
                    <div className="tab-one__content">
                      <div className="tab-one__content__img">
                        <img
                          src="assets/images/madinahotels/alfalahsuites.jpeg"
                          alt="travel"
                        />
                        <div className="tab-one__content__icon">
                          <span className="icon-tent-1" />
                        </div>
                      </div>
                      <h4 className="tab-one__content__title">
                        Al Falah Suites
                      </h4>
                      <p className="tab-one__content__text">
                        The hotel has everything you need for a comfortable
                        stay. Facilities like 24-hour room service, free Wi-Fi
                        in all rooms, 24-hour security, daily housekeeping,
                        grocery deliveries are readily available for you to
                        enjoy. All rooms are designed and decorated to make
                        guests feel right at home, and some rooms come with
                        television LCD/plasma screen, additional bathroom,
                        cleaning products, mirror, slippers. The hotel offers
                        various recreational opportunities.
                      </p>
                      <ul className="tab-one__content__list">
                        <li>
                          <span className="far fa-check-circle" />
                          24/7 Service
                        </li>
                        <li>
                          <span className="far fa-check-circle" />
                          Luxury Accomodation
                        </li>
                      </ul>
                      <div className="clearfix" />
                    </div>
                  </div>
                  <div
                    className="tab fadeInUp animated active-tab"
                    id="arjawan"
                  >
                    <div className="tab-one__content">
                      <div className="tab-one__content__img">
                        <img
                          src="assets/images/madinahotels/arjawan.jpg"
                          alt="travel"
                        />
                        <div className="tab-one__content__icon">
                          <span className="icon-traveling-1" />
                        </div>
                      </div>
                      <h4 className="tab-one__content__title">
                        ArJawan Al Madina
                      </h4>
                      <p className="tab-one__content__text">
                        Al Madinah Ergwan Hotel is located in western central
                        area of Prophet's mosque. Te hotel is 35 minutes drive
                        from Madinah International Airport. The hotel offers
                        comfortable stay to his guests with variety of amenities
                        like free wifi in public areas, restaurant facility,
                        free parking.
                      </p>
                      <ul className="tab-one__content__list">
                        <li>
                          <span className="far fa-check-circle" />
                          24/7 Service
                        </li>
                        <li>
                          <span className="far fa-check-circle" />
                          Luxury Accomodation
                        </li>
                      </ul>
                      <div className="clearfix" />
                    </div>
                  </div>
                  <div className="tab fadeInUp animated" id="wardasafa">
                    <div className="tab-one__content">
                      <div className="tab-one__content__img">
                        <img
                          src="assets/images/madinahotels/wardasafa.jpg"
                          alt="travel"
                        />
                        <div className="tab-one__content__icon">
                          <span className="icon-mountain-bike-1" />
                        </div>
                      </div>
                      <h4 className="tab-one__content__title">Warda Safa</h4>
                      <p className="tab-one__content__text">
                        There are many variations of passages of Lorem Ipsum
                        available, but the majority have suffered alteradution
                        in some form by injected humour, some form
                      </p>
                      <ul className="tab-one__content__list">
                        <li>
                          <span className="far fa-check-circle" />
                          24/7 Service
                        </li>
                        <li>
                          <span className="far fa-check-circle" />
                          Luxury Accomodation
                        </li>
                      </ul>
                      <div className="clearfix" />
                    </div>
                  </div>
                  <div className="tab fadeInUp animated" id="diyarsaatir">
                    <div className="tab-one__content">
                      <div className="tab-one__content__img">
                        <img
                          src="assets/images/madinahotels/diyarsaatir.jpg"
                          alt="travel"
                        />
                        <div className="tab-one__content__icon">
                          <span className="icon-world-1" />
                        </div>
                      </div>
                      <h4 className="tab-one__content__title">Diyar Saatir</h4>
                      <p className="tab-one__content__text">
                        Situated in Al Madinah, 5.5 km from Jabal Ahad Garden
                        Park, DIYAR AL SATER features views of the city. With a
                        restaurant, the 3-star hotel has air-conditioned rooms
                        with free WiFi, each with a private bathroom. The
                        property is non-smoking and is set 4.3 km from Al-Masjid
                        an-Nabawi.
                      </p>
                      <ul className="tab-one__content__list">
                        <li>
                          <span className="far fa-check-circle" />
                          24/7 Front Desk
                        </li>
                        <li>
                          <span className="far fa-check-circle" />
                          Luxury Acoomodation
                        </li>
                      </ul>
                      <div className="clearfix" />
                    </div>
                  </div>
                  <div className="tab fadeInUp animated" id="marjan">
                    <div className="tab-one__content">
                      <div className="tab-one__content__img">
                        <img
                          src="assets/images/madinahotels/marjaninternational.jpg"
                          alt="travel"
                        />
                        <div className="tab-one__content__icon">
                          <span className="icon-fishing-net-1" />
                        </div>
                      </div>
                      <h4 className="tab-one__content__title">
                        Marjan International
                      </h4>
                      <p className="tab-one__content__text">
                        Well set in the Central Madinah district of Al Madinah,
                        Murjan Al Madinah Hotel is located 300m from Al-Masjid
                        an-Nabawi, 350m from Old Bazaar and 2.8 km from Mazaya
                        Mall. Among the facilities of this property are a
                        restaurant, a 24-hour front desk and room service, along
                        with free WiFi. The hotel features family rooms.
                      </p>
                      <ul className="tab-one__content__list">
                        <li>
                          <span className="far fa-check-circle" />
                          24/7 Front Desk Service
                        </li>
                        <li>
                          <span className="far fa-check-circle" />
                          Luxury Accomodation
                        </li>
                      </ul>
                      <div className="clearfix" />
                    </div>
                  </div>
                  <div className="tab fadeInUp animated" id="alaws">
                    <div className="tab-one__content">
                      <div className="tab-one__content__img">
                        <img
                          src="assets/images/madinahotels/alaws.jpg"
                          alt="travel"
                        />
                        <div className="tab-one__content__icon">
                          <span className="icon-paragliding-1" />
                        </div>
                      </div>
                      <h4 className="tab-one__content__title">Al Aws</h4>
                      <p className="tab-one__content__text">
                        Set in Al Madinah and within 1.9 km of Al-Masjid
                        an-Nabawi, AL AWS HOTEL - SHALAL has a restaurant,
                        non-smoking rooms, and free WiFi throughout the
                        property. The property is situated 4.8 km from Qiblatain
                        Mosque, 5.1 km from Quba Mosque and 7.1 km from Mount
                        Uhud. The hotel features family rooms.
                      </p>
                      <ul className="tab-one__content__list">
                        <li>
                          <span className="far fa-check-circle" />
                          24/7 Front Desk
                        </li>
                        <li>
                          <span className="far fa-check-circle" />
                          Luxury Accomodation
                        </li>
                      </ul>
                      <div className="clearfix" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /.client-carousel */}
        </section>
        {/* Contact Page Start */}
        <section
          className="contact-page section-space-top"
          style={{ backgroundColor: "#241C26" }}
        >
          <div className="container">
            <div className="sec-title text-center">
              <p className="sec-title__tagline">Contact Us</p>
              {/* /.sec-title__tagline */}
              <h2 className="sec-title__title text-white">
                Feel Free to Write us Anytime
              </h2>
              {/* /.sec-title__title */}
            </div>
            {/* /.sec-title */}
            {/* /.sec-title */}
            <form
              action="assets/inc/sendemail.php.html"
              className="contact-page__form form-one row gutter-20 contact-form-validated"
            >
              <div
                className="col-md-6 wow animated fadeInUp"
                data-wow-delay="0s"
                data-wow-duration="1500ms"
              >
                <div className="form-one__group">
                  <input
                    type="text"
                    name="name"
                    id="form-one-name-input"
                    placeholder="Your Name"
                    className="form-one__input"
                  />
                </div>
                {/* /.form-one__group */}
              </div>
              {/* /.col-md-6 */}
              <div
                className="col-md-6 wow animated fadeInUp"
                data-wow-delay="0.3s"
                data-wow-duration="1500ms"
              >
                <div className="form-one__group">
                  <input
                    type="email"
                    name="email"
                    id="form-one-email-input"
                    placeholder="Email Address"
                    className="form-one__input"
                  />
                </div>
                {/* /.form-one__group */}
              </div>
              {/* /.col-md-6 */}
              <div
                className="col-md-6 wow animated fadeInUp"
                data-wow-delay="0s"
                data-wow-duration="1500ms"
              >
                <div className="form-one__group">
                  <input
                    type="tel"
                    name="phone"
                    id="form-one-phone-input"
                    placeholder="Phone"
                    className="form-one__input"
                  />
                </div>
                {/* /.form-one__group */}
              </div>
              {/* /.col-md-6 */}
              <div
                className="col-md-6 wow animated fadeInUp"
                data-wow-delay="0.3s"
                data-wow-duration="1500ms"
              >
                <div className="form-one__group">
                  <input
                    type="text"
                    name="subject"
                    id="form-one-subject-input"
                    placeholder="Subject"
                    className="form-one__input"
                  />
                </div>
                {/* /.form-one__group */}
              </div>
              {/* /.col-md-6 */}
              <div
                className="col-12 wow animated fadeInUp"
                data-wow-delay="0.1s"
                data-wow-duration="1500ms"
              >
                <div className="form-one__group">
                  <textarea
                    name="message"
                    id="form-one-message-input"
                    cols={30}
                    rows={10}
                    placeholder="Write a Message"
                    className="form-one__message form-one__input"
                    defaultValue={""}
                  />
                </div>
                {/* /.form-one__group */}
              </div>
              {/* /.col-12*/}
              <div
                className="col-12 wow animated fadeInUp"
                data-wow-delay="0.2s"
                data-wow-duration="1500ms"
              >
                <div className="form-one__btn-box">
                  <button
                    type="submit"
                    className="form-one__btn trevlo-btn trevlo-btn--base"
                  >
                    <span>Send Message</span>
                  </button>
                </div>
                {/* /.form-one__btn-box */}
              </div>
              {/* /.col-12*/}
            </form>
            {/* /.row */}
            <div className="result" />
            {/* /.result */}
          </div>
          {/* /.container */}
          <div className="contact-page__info">
            <div className="contact-page__info-container container">
              <div className="contact-page__info-top">
                <p className="contact-page__info-top-title">
                  Contact Information
                </p>
              </div>
              {/* /.contact-page__info-top */}
              <div className="contact-page__info-row row">
                <div
                  className="col-lg-4 wow animated fadeInUp"
                  data-wow-delay="0s"
                  data-wow-duration="1500ms"
                >
                  <div className="contact-page__info-box">
                    <div className="contact-page__info-icon-box">
                      <span className="icon-phone-1" />
                    </div>
                    <div className="contact-page__info-text-box">
                      <p className="contact-page__info-title">
                        Have any question?
                      </p>
                      <a href="tel:+923224070193">
                        <p className="contact-page__info-text contact-page__info-text-link">
                          +923224070193
                        </p>
                      </a>
                    </div>
                  </div>
                  {/* /.contact-page__info-box */}
                </div>
                <div
                  className="col-lg-3 wow animated fadeInUp"
                  data-wow-delay="0.3s"
                  data-wow-duration="1500ms"
                >
                  <div className="contact-page__info-box">
                    <div className="contact-page__info-icon-box">
                      <span className="icon-envelope" />
                    </div>
                    <div className="contact-page__info-text-box">
                      <p className="contact-page__info-title">Send Email</p>
                      <a href="mailto:help@trevlo.com">
                        <p className="contact-page__info-text contact-page__info-text-link">
                          pakqurtuba@gmail.com
                        </p>
                      </a>
                    </div>
                  </div>
                  {/* /.contact-page__info-box */}
                </div>
                <div
                  className="col-lg-5 wow animated fadeInUp"
                  data-wow-delay="0.6s"
                  data-wow-duration="1500ms"
                >
                  <div className="contact-page__info-box contact-page__info-box--three">
                    <div className="contact-page__info-icon-box">
                      <span className="icon-location-1" />
                    </div>
                    <div className="contact-page__info-text-box">
                      <p className="contact-page__info-title">Visit Anytime</p>
                      <p className="contact-page__info-text">
                        Near Jazz Franchise Johar Town,Lahore, Pakistan
                      </p>
                    </div>
                  </div>
                  {/* /.contact-page__info-box */}
                </div>
              </div>
              {/* /.row */}
            </div>
            {/* /.container */}
          </div>
          {/* /.contact-page__info */}
          <div className="google-map google-map__@@extraClassName">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d235.56737789312402!2d74.27313421070579!3d31.4692908419389!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391903c379c19c35%3A0xe6dc183ed6ebacef!2sPak%20Qurtuba%20Travels%20(Pvt)%20Ltd!5e0!3m2!1sen!2s!4v1708864111073!5m2!1sen!2s"
              width={600}
              height={450}
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              title="officelocation"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
          {/* /.google-map */}
        </section>

        <Footer />
        <Mobilenav />
      </div>
    </div>
  );
}

export default Hotels;
