import React from 'react'
import Navbar from '../components/common/Navbar'
import Footer from '../components/common/Footer'
import Mobilenav from '../components/common/Mobilenav'

function Aboutus() {
  return (
    <div>
      <Navbar/>
     <div class="page-wrapper">
  <section className="page-header">
    <div className="page-header__bg" />
    {/* /.page-header__bg */}
    <div className="container">
      <h2 className="page-header__title wow animated fadeInLeft" data-wow-delay="0s" data-wow-duration="1500ms">About</h2>
      <div className="page-header__breadcrumb-box">
        <ul className="trevlo-breadcrumb">
          <li><a href="/">Home</a></li>
          <li>About</li>
        </ul>{/* /.trevlo-breadcrumb */}
      </div>{/* /.page-header__breadcrumb-box */}
    </div>{/* /.container */}
  </section>{/* /.page-header */}
  {/* About Four Start */}
  <section className="about-four section-space-top">
    <div className="container">
      <div className="row">
        <div className="col-xl-6 col-lg-6 about-four__img-column wow animated fadeInLeft" data-wow-delay="0.1s" data-wow-duration="1500ms">
          <div className="about-four__img-box">
            <div className="about-four__inner-img-box-one">
              <img src="./assets/images/about/about-4-1.jpg" alt="about image" className="about-four__img-one" />
              <img src="./assets/images/shapes/about-shape-4-1.png" alt="about-shape" className="about-four__shape-one" />
              <img src="./assets/images/shapes/about-shape-4-2.png" alt="about-shape" className="about-four__shape-two" />
            </div>{/* /.about-four__inner-img-box-one */}
            <div className="about-four__inner-img-box-two">
              <img src="./assets/images/about/about-4-2.jpg" alt="about image" className="about-four__img-two" />
              <div className="about-four__discount">
                <div className="about-four__discount-inner">
                  <h3 className="about-four__discount-percentage">30<span>%</span></h3>
                  <h4 className="about-four__discount-title">Discount</h4>
                </div>{/* /.about-four__discount-inner */}
              </div>{/* /.about-four__discount-box */}
            </div>{/* /.about-four__inner-img-box-two */}
          </div>{/* /.about-four__img-box */}
        </div>{/* /.col-xl-6 col-lg-6 */}
        <div className="col-xl-6 col-lg-6 about-four__content-column">
          <div className="about-four__content">
            <div className="sec-title text-left">
              <p className="sec-title__tagline">GET TO KNOW US</p>{/* /.sec-title__tagline */}
              <h2 className="sec-title__title">Experience the World with Our Trevlo Company</h2>{/* /.sec-title__title */}
            </div>{/* /.sec-title */}{/* /.sec-title */}
            <p className="about-four__text">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some</p>
            <div className="about-four__service">
              <div className="about-four__service-box wow animated fadeInUp" data-wow-delay="0s" data-wow-duration="1500ms">
                <div className="about-four__service-icon">
                  <span className="icon-safety" />
                </div>{/* /.about-four__service-icon */}
                <div className="about-four__service-content">
                  <h4 className="about-four__service-title">Safety First<br /> Always</h4>
                </div>{/* /.about-four__service-content */}
              </div>{/* /.about-four__service-box */}
              <div className="about-four__service-box wow animated fadeInUp" data-wow-delay="0.3s" data-wow-duration="1500ms">
                <div className="about-four__service-icon">
                  <span className="icon-friendly-Guide" />
                </div>{/* /.about-four__service-icon */}
                <div className="about-four__service-content">
                  <h4 className="about-four__service-title">Friendly<br /> Guide</h4>
                </div>{/* /.about-four__service-content */}
              </div>{/* /.about-four__service-box */}
            </div>{/* /.about-four__service */}
            <div className="trevlo-progress about-four__progress">
              <h4 className="trevlo-progress__title">Experience</h4>
              <div className="trevlo-progress__bar">
                <div className="trevlo-progress__inner count-bar counted" data-percent="80%">
                  <p className="trevlo-progress__number count-text">80%</p>
                </div>
              </div>
            </div>{/* /.trevlo-progress */}
            <div className="about-four__btn-box wow animated fadeInUp" data-wow-delay="0s" data-wow-duration="1500ms">
              <a href="about.html" className="about-four__btn trevlo-btn"><span>Discover More</span></a>
            </div>{/* /.about-four__btn-box */}
          </div>{/* /.about-four__content */}
        </div>{/* /.col-xl-6 col-lg-6 */}
      </div>{/* /.row */}
    </div>
  </section>
  {/* About Four End */}
  {/* CTA Two Start */}
  <section className="cta-three" >
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 wow slideInLeft" data-wow-delay="100ms">
          <div className="cta-three__image">
            <img src="./assets/images/resources/cta-3-1.jpg" alt="trevlo" />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="cta-three__content" style={{backgroundImage: 'url("./assets/images/shapes/cta-3-2.png")'}}>
            <div className="cta-three__content__inner wow fadeInUp" data-wow-delay="200ms">
              <div className="cta-three__content__inner__bg" style={{backgroundImage: 'url(./assets/images/shapes/cta-3-3.png)'}} />
              <h2 className="cta-three__title">Singapore</h2>
              <h5 className="cta-three__sub-title">A Simply Perfect Place to Get Lost</h5>
              <a href="about.html" className="trevlo-btn trevlo-btn--base"><span>Discover More</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>{/* /.container */}
  </section>
  {/* CTA Two End */}
  {/* Testimonial One Start */}
  <section className="testimonial-one">
    <div className="testimonial-one__bg trevlo-splax" style={{backgroundImage: 'url(./assets/images/backgrounds/tetsimonial-1-bg.png)'}} data-para-options="{
  &quot;orientation&quot;: &quot;down&quot;,
  &quot;scale&quot;: 1.5,
  &quot;delay&quot;: &quot;.3&quot;,
  &quot;transition&quot;: &quot;cubic-bezier(0,0,0,1)&quot;,
  &quot;overflow&quot;: true
  }">
    </div>{/* /.testimonial-one__bg */}
    <div className="container">
      <div className="sec-title text-center">
        <p className="sec-title__tagline">Testimonial</p>{/* /.sec-title__tagline */}
        <h2 className="sec-title__title">What Our Customers are<br /> Saying?</h2>{/* /.sec-title__title */}
      </div>{/* /.sec-title */}{/* /.sec-title */}
    </div>{/* /.container */}
    <div className="container">
      <div className="testimonial-one__carousel trevlo-owl__carousel trevlo-owl__carousel--basic-nav trevlo-owl__carousel--with-shadow owl-theme owl-carousel" data-owl-options="{
      &quot;items&quot;: 3,
      &quot;margin&quot;: 42,
      &quot;smartSpeed&quot;: 700,
      &quot;loop&quot;:false,
      &quot;autoplay&quot;: 6000,
      &quot;nav&quot;:false,
      &quot;dots&quot;:true,
      &quot;navText&quot;: [&quot;<span class=\&quot;fa fa-angle-left\&quot;></span>&quot;,&quot;<span class=\&quot;fa fa-angle-right\&quot;></span>&quot;],
      &quot;responsive&quot;:{
          &quot;0&quot;:{
              &quot;items&quot;: 1
          },
          &quot;768&quot;:{
              &quot;items&quot;: 2
          },
          &quot;1200&quot;:{
              &quot;items&quot;: 3,
              &quot;dots&quot;: false
          }
      }
      }">
        <div className="item">
          <div className="testimonials-card">
            <div className="testimonials-card__image">
              <img src="./assets/images/testimonial/testimonial-1-1.jpg" alt="Jacob Jones" />
            </div>
            <div className="testimonials-card__meta">
              <h5 className="testimonials-card__meta__name">Jacob Jones</h5>
              <p className="testimonials-card__meta__designation">Tourist</p>
            </div>{/* /.testimonials-card__meta */}
            <div className="testimonials-card__ratings">
              <span className="icon-star" />
              <span className="icon-star" />
              <span className="icon-star" />
              <span className="icon-star" />
              <span className="icon-star" />
            </div>{/* /.testimonials-card__ratings */}
            <div className="testimonials-card__quote">Flexible Classes refers to the process of acquiring is knowledge free or skills through the use of process the digital technologies and the internet. Flexible Classes</div>{/* /.testimonials-card__quote */}
          </div>{/* /.testimonials-card */}
        </div>{/* /.owl-slide-item*/}
        <div className="item">
          <div className="testimonials-card">
            <div className="testimonials-card__image">
              <img src="./assets/images/testimonial/testimonial-1-2.jpg" alt="Robert Fox" />
            </div>
            <div className="testimonials-card__meta">
              <h5 className="testimonials-card__meta__name">Robert Fox</h5>
              <p className="testimonials-card__meta__designation">Tourist</p>
            </div>{/* /.testimonials-card__meta */}
            <div className="testimonials-card__ratings">
              <span className="icon-star" />
              <span className="icon-star" />
              <span className="icon-star" />
              <span className="icon-star" />
              <span className="icon-star" />
            </div>{/* /.testimonials-card__ratings */}
            <div className="testimonials-card__quote">Flexible Classes refers to the process of acquiring is knowledge free or skills through the use of process the digital technologies and the internet. Flexible Classes</div>{/* /.testimonials-card__quote */}
          </div>{/* /.testimonials-card */}
        </div>{/* /.owl-slide-item*/}
        <div className="item">
          <div className="testimonials-card">
            <div className="testimonials-card__image">
              <img src="./assets/images/testimonial/testimonial-1-3.jpg" alt="Guy Hawkins" />
            </div>
            <div className="testimonials-card__meta">
              <h5 className="testimonials-card__meta__name">Guy Hawkins</h5>
              <p className="testimonials-card__meta__designation">Tourist</p>
            </div>{/* /.testimonials-card__meta */}
            <div className="testimonials-card__ratings">
              <span className="icon-star" />
              <span className="icon-star" />
              <span className="icon-star" />
              <span className="icon-star" />
              <span className="icon-star" />
            </div>{/* /.testimonials-card__ratings */}
            <div className="testimonials-card__quote">Flexible Classes refers to the process of acquiring is knowledge free or skills through the use of process the digital technologies and the internet. Flexible Classes</div>{/* /.testimonials-card__quote */}
          </div>{/* /.testimonials-card */}
        </div>{/* /.owl-slide-item*/}
      </div>{/* /.thm-owl__slider */}
    </div>{/* /.container */}
  </section>
  {/* Testimonial One End */}
  {/* Why Choose Two Start */}
  <section className="why-choose-two" style={{backgroundColor:"#241C26"}}>
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-xl-6 wow fadeInLeft" data-wow-delay="200ms">
          <div className="why-choose-two__content">
            <div className="sec-title text-left">
              <p className="sec-title__tagline">Are you ready to travel</p>{/* /.sec-title__tagline */}
              <h2 className="sec-title__title text-white">Choose Our Tour Types &amp; Enjoy Now</h2>{/* /.sec-title__title */}
            </div>{/* /.sec-title */}
            <p className="why-choose-two__text">
              There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteradution in some
              form by injected humour, some form
            </p>
            <div className="trevlo-progress why-choose-two__progress">
              <h4 className="trevlo-progress__title text-white">Experience</h4>
              <div className="trevlo-progress__bar">
                <div className="trevlo-progress__inner count-bar counted" data-percent="80%">
                  <p className="trevlo-progress__number count-text text-white">80%</p>
                </div>
              </div>
            </div>{/* /.trevlo-progress */}
            <div className="why-choose-two__box-wrapper">
              <div className="why-choose-two__box">
                <div className="why-choose-two__box__icon">
                  <span className="icon-low-price" />
                </div>{/* /.why-choose-two__box__icon */}
                <h4 className="why-choose-two__box__title text-white">Low Price Friendly</h4>
              </div>{/* /.why-choose-two__box */}
              <div className="why-choose-two__box">
                <div className="why-choose-two__box__icon">
                  <span className="icon-safety-First" />
                </div>{/* /.why-choose-two__box__icon */}
                <h4 className="why-choose-two__box__title text-white">Safety First always</h4>
              </div>{/* /.why-choose-two__box */}
            </div>{/* /.why-choose-two__service */}
            <a href="about.html" className="trevlo-btn trevlo-btn--base"><span>Discover More</span></a>
          </div>{/* /.why-choose-two__content */}
        </div>{/* /.col-lg-6 col-xl-6 */}
        <div className="col-lg-6 col-xl-6">
          <div className="why-choose-two__img">
            <div className="why-choose-two__img__one wow fadeInUp" data-wow-delay="200ms">
              <div className="trevlo-tilt" data-tilt-options="{ &quot;glare&quot;: false, &quot;maxGlare&quot;: 0, &quot;maxTilt&quot;: 7, &quot;speed&quot;: 700, &quot;scale&quot;: 1 }">
                <img src="./assets/images/why-choose/why-choose-2-1.jpg" alt="why-choose" />
              </div>
              <div className="trevlo-tilt" data-tilt-options="{ &quot;glare&quot;: false, &quot;maxGlare&quot;: 0, &quot;maxTilt&quot;: 7, &quot;speed&quot;: 700, &quot;scale&quot;: 1 }">
                <img src="./assets/images/why-choose/why-choose-2-2.jpg" alt="why-choose" />
              </div>
            </div>{/* /.why-choose-two__img__one */}
            <div className="why-choose-two__img__two wow fadeInUp" data-wow-delay="300ms">
              <div className="trevlo-tilt" data-tilt-options="{ &quot;glare&quot;: false, &quot;maxGlare&quot;: 0, &quot;maxTilt&quot;: 7, &quot;speed&quot;: 700, &quot;scale&quot;: 1 }">
                <img src="./assets/images/why-choose/why-choose-2-3.jpg" alt="why-choose" />
              </div>
              <div className="trevlo-tilt" data-tilt-options="{ &quot;glare&quot;: false, &quot;maxGlare&quot;: 0, &quot;maxTilt&quot;: 7, &quot;speed&quot;: 700, &quot;scale&quot;: 1 }">
                <img src="./assets/images/why-choose/why-choose-2-4.jpg" alt="why-choose" />
              </div>
            </div>{/* /.why-choose-two__img__two */}
          </div>{/* /.why-choose-two__img-box */}
        </div>{/* /.col-lg-6 col-xl-6 */}
      </div>{/* /.row */}
    </div>{/* /.container */}
  </section>
  {/* Why Choose Two End */}
  {/* Offer One Start */}
  <section className="offer-one" style={{backgroundImage: 'url("./assets/images/backgrounds/offer-1-bg.png")'}}>
    <div className="container">
      <div className="row">
        <div className="col-xl-5 col-lg-6">
          <div className="offer-one__content sec-title">
            <p className="offer-one__top-title">Special offer for you</p>
            <h2 className="offer-one__heading sec-title__heading">Start your Journey with a Single Click</h2>
            <p className="offer-one__text">There are many variations of passages of Lorem Ipsum available, but the majority have</p>
            <div className="offer-one__btn-box wow animated fadeInUp" data-wow-delay="0.1s" data-wow-duration="1500ms">
              <a href="tour-listing-side-filter-right.html" className="offer-one__btn trevlo-btn trevlo-btn--primary"><span>Start Booking</span></a>
            </div>{/* /.offer-one__btn-box */}
          </div>{/* /.offer-one__content */}
        </div>{/* /.col-xl-5 col-lg-6 */}
        <div className="col-xl-7 col-lg-6 wow animated fadeInRight" data-wow-delay="0.1s" data-wow-duration="1500ms">
          <div className="offer-one__img-box">
            <div className="offer-one__inner-img-box">
            <img src="./assets/images/offer/bg3.jpg" alt="offer" className="offer-one__img-one" />
              <img src="./assets/images/offer/offerr.jpg" alt="offer" className="offer-one__img-two" />
              <img src="./assets/images/offer/offer-1-3.png" alt="offer" className="offer-one__img-three" />
            </div>{/* /.offer-one__inner-img-box */}
          </div>{/* /.offer-one__img-box */}
        </div>{/* /.col-xl-7 col-lg-6 */}
      </div>{/* /.row */}
    </div>{/* /.container */}
    <div className="offer-one__shape-one trevlo-splax" data-para-options="{ &quot;orientation&quot;: &quot;left&quot;, &quot;scale&quot;: 1.5, &quot;overflow&quot;: true }" style={{backgroundImage: 'url("./assets/images/shapes/offer-shape-1.png")'}} />{/* /.bg */}
    <div className="offer-one__shape-two trevlo-splax" data-para-options="{ &quot;orientation&quot;: &quot;right&quot;, &quot;scale&quot;: 1.5, &quot;overflow&quot;: true }" style={{backgroundImage: 'url("./assets/images/shapes/offer-shape-2.png")'}} />{/* /.bg */}
    <div className="offer-one__bottom-bg" style={{backgroundImage: 'url("./assets/images/offer/offer-1-4.png")'}} />{/* /.bg */}
  </section>
  {/* Offer End Start */}
  {/* Counter One Start */}
  <section className="counter-one"  style={{backgroundColor:"#241C26"}}>
    <div className="counter-one__bg-box" />{/* /.counter-one__bg-box */}
    <div className="counter-one__main-content">
      <div className="container">
        <div className="counter-one__container container-fluid">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-6 wow animated fadeInUp" data-wow-delay="0.1s" data-wow-duration="1500ms">
              <div className="counter-box @@extraClassName">
                <div className="counter-box__icon">
                  <span className="icon-happy-Travel" />
                </div>{/* /.counter-box__icon */}
                <div className="counter-box__inner sec-title count-box">
                  <h3 className="counter-box__count-text counter-box__count-text--one sec-title__heading count-text" data-stop="30.3" data-speed={1500}>00</h3>
                  <h3 className="counter-box__count-text sec-title__heading">k</h3>
                </div>{/* /.counter-box__inner */}
                <p className="counter-box__title">Happy Traveller</p>
              </div>
            </div>{/* /.col-xl-3 col-lg-3 col-6 */}
            <div className="col-xl-3 col-lg-3 col-6 wow animated fadeInUp" data-wow-delay="0.3s" data-wow-duration="1500ms">
              <div className="counter-box @@extraClassName">
                <div className="counter-box__icon">
                  <span className="icon-tent-1" />
                </div>{/* /.counter-box__icon */}
                <div className="counter-box__inner sec-title count-box">
                  <h3 className="counter-box__count-text counter-box__count-text--one sec-title__heading count-text" data-stop="40.5" data-speed={1500}>00</h3>
                  <h3 className="counter-box__count-text sec-title__heading">k</h3>
                </div>{/* /.counter-box__inner */}
                <p className="counter-box__title">Tent Sites</p>
              </div>
            </div>{/* /.col-xl-3 col-lg-3 col-6 */}
            <div className="col-xl-3 col-lg-3 col-6 wow animated fadeInUp" data-wow-delay="0.5s" data-wow-duration="1500ms">
              <div className="counter-box @@extraClassName">
                <div className="counter-box__icon">
                  <span className="icon-satisfied" />
                </div>{/* /.counter-box__icon */}
                <div className="counter-box__inner sec-title count-box">
                  <h3 className="counter-box__count-text counter-box__count-text--one sec-title__heading count-text" data-stop="88.9" data-speed={1500}>00</h3>
                  <h3 className="counter-box__count-text sec-title__heading">%</h3>
                </div>{/* /.counter-box__inner */}
                <p className="counter-box__title">Satisfaction Rate</p>
              </div>
            </div>{/* /.col-xl-3 col-lg-3 col-6 */}
            <div className="col-xl-3 col-lg-3 col-6 wow animated fadeInUp" data-wow-delay="0.7s" data-wow-duration="1500ms">
              <div className="counter-box counter-box--no-border">
                <div className="counter-box__icon">
                  <span className="icon-online-chat-1" />
                </div>{/* /.counter-box__icon */}
                <div className="counter-box__inner sec-title count-box">
                  <h3 className="counter-box__count-text counter-box__count-text--one sec-title__heading count-text" data-stop="6.30" data-speed={1500}>00</h3>
                  <h3 className="counter-box__count-text sec-title__heading">+</h3>
                </div>{/* /.counter-box__inner */}
                <p className="counter-box__title">Year Of Service</p>
              </div>
            </div>{/* /.col-xl-3 col-lg-3 col-6 */}
          </div>{/* /.row */}
        </div>{/* /.counter-one__container container-fluid */}
      </div>{/* /.container */}
    </div>{/* /.counter-one__main-content */}
  </section>
  {/* Counter One End */}
  {/* Guide One Start */}

  {/* Guide One End */}
</div>
      <Footer/>
      <Mobilenav/>

    </div>
  )
}

export default Aboutus
