import React from "react";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import Mobilenav from "../components/common/Mobilenav";

function HajjForm() {
  return (
    <div>
      <div class="page-wrapper">
        <Navbar />
        <section className="page-header">
          <div className="page-header__bg" />
          {/* /.page-header__bg */}
          <div className="container">
            <h2
              className="page-header__title wow animated fadeInLeft"
              data-wow-delay="0s"
              data-wow-duration="1500ms"
            >
              Hajj Registration
            </h2>
            <div className="page-header__breadcrumb-box">
              <ul className="trevlo-breadcrumb">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Hajj </li>
              </ul>
              {/* /.trevlo-breadcrumb */}
            </div>
            {/* /.page-header__breadcrumb-box */}
          </div>
          {/* /.container */}
        </section>
        {/* /.page-header */}

        {/* Contact Page Start */}
        <section
          className="contact-page section-space-top"
          style={{ backgroundColor: "#241C26" }}
        >
          <div className="container">
            <div className="sec-title text-center">
              <p className="sec-title__tagline">Hajj Form Registration</p>
              {/* /.sec-title__tagline */}
              <h2 className="sec-title__title text-white">
                Feel Free to Write us Anytime
              </h2>
              {/* /.sec-title__title */}
            </div>
            {/* /.sec-title */}
            {/* /.sec-title */}
            <form
              action="assets/inc/sendemail.php.html"
              className="contact-page__form form-one row gutter-20 contact-form-validated"
            >
              <div
                className="col-md-6 wow animated fadeInUp"
                data-wow-delay="0s"
                data-wow-duration="1500ms"
              >
                <div className="form-one__group">
                  <input
                    type="text"
                    name="id"
                    id="form-one-name-input"
                    placeholder="ID No"
                    className="form-one__input"
                  />
                </div>
                {/* /.form-one__group */}
              </div>
              <div
                className="col-md-6 wow animated fadeInUp"
                data-wow-delay="0s"
                data-wow-duration="1500ms"
              >
                <div className="form-one__group">
                  <input
                    type="text"
                    name="cnic"
                    id="form-one-name-input"
                    placeholder="CNIC#"
                    className="form-one__input"
                  />
                </div>
                {/* /.form-one__group */}
              </div>
              {/* /.col-md-6 */}
              <div
                className="col-md-6 wow animated fadeInUp"
                data-wow-delay="0s"
                data-wow-duration="1500ms"
              >
                <div className="form-one__group">
                  <input
                    type="text"
                    name="hajjapplicationnumber"
                    id="form-one-name-input"
                    placeholder="Hajj Application Number"
                    className="form-one__input"
                  />
                </div>
                {/* /.form-one__group */}
              </div>
              {/* /.col-md-6 */}
              <div
                className="col-md-6 wow animated fadeInUp"
                data-wow-delay="0s"
                data-wow-duration="1500ms"
              >
                <div className="form-one__group">
                  <input
                    type="text"
                    name="passportnumber"
                    id="form-one-name-input"
                    placeholder="Passport Number"
                    className="form-one__input"
                  />
                </div>
                {/* /.form-one__group */}
              </div>
              {/* /.col-md-6 */}
              <div
                className="col-md-6 wow animated fadeInUp"
                data-wow-delay="0s"
                data-wow-duration="1500ms"
              >
                <div className="form-one__group">
                  <label htmlFor="" className="text-white">
                    Date of Expiry
                  </label>
                  <input
                    type="date"
                    name="dateofexpiry"
                    id="form-one-name-input"
                    placeholder="Date Of Expiry"
                    className="form-one__input"
                  />
                </div>
                {/* /.form-one__group */}
              </div>
              {/* /.col-md-6 */}
              <div
                className="col-md-6 wow animated fadeInUp"
                data-wow-delay="0s"
                data-wow-duration="1500ms"
              >
                <div className="form-one__group">
                  <label htmlFor="" className="text-white">
                    Year Of Birth
                  </label>
                  <input
                    type="date"
                    name="yearofbirth"
                    id="form-one-name-input"
                    placeholder="Year Of Birth"
                    className="form-one__input"
                  />
                </div>
                {/* /.form-one__group */}
              </div>
              {/* /.col-md-6 */}
              <div
                className="col-md-6 wow animated fadeInUp"
                data-wow-delay="0s"
                data-wow-duration="1500ms"
              >
                <div className="form-one__group">
                  <input
                    type="text"
                    name="surname"
                    id="form-one-name-input"
                    placeholder="Sur Name As per Passport"
                    className="form-one__input"
                  />
                </div>
                {/* /.form-one__group */}
              </div>
              {/* /.col-md-6 */}
              {/* /.col-md-6 */}
              <div
                className="col-md-6 wow animated fadeInUp"
                data-wow-delay="0.3s"
                data-wow-duration="1500ms"
              >
                <div className="form-one__group">
                  <input
                    type="text"
                    name="givenname"
                    id="form-one-email-input"
                    placeholder="Given Name As Per Passport"
                    className="form-one__input"
                  />
                </div>
                {/* /.form-one__group */}
              </div>
              {/* /.col-md-6 */}
              <div
                className="col-md-6 wow animated fadeInUp"
                data-wow-delay="0s"
                data-wow-duration="1500ms"
              >
                <div className="form-one__group">
                  <input
                    type="text"
                    name="fatherorhusbandname"
                    id="form-one-phone-input"
                    placeholder="Father/Husband Name As Per Passport"
                    className="form-one__input"
                  />
                </div>
                {/* /.form-one__group */}
              </div>
              {/* /.col-md-6 */}
              <div
                className="col-md-6 wow animated fadeInUp"
                data-wow-delay="0.3s"
                data-wow-duration="1500ms"
              >
                <div className="form-one__group">
                  <input
                    type="text"
                    name="postaladdress"
                    id="form-one-subject-input"
                    placeholder="Present Portal Address"
                    className="form-one__input"
                  />
                </div>
                {/* /.form-one__group */}
              </div>
              {/* /.col-md-6 */}
              {/* /.col-md-6 */}
              <div
                className="col-md-6 wow animated fadeInUp"
                data-wow-delay="0.3s"
                data-wow-duration="1500ms"
              >
                <div className="form-one__group">
                  <input
                    type="text"
                    name="bloodgroup"
                    id="form-one-subject-input"
                    placeholder="Blood Group"
                    className="form-one__input"
                  />
                </div>
                {/* /.form-one__group */}
              </div>
              {/* /.col-md-6 */}
              {/* /.col-md-6 */}
              <div
                className="col-md-6 wow animated fadeInUp"
                data-wow-delay="0.3s"
                data-wow-duration="1500ms"
              >
                <div className="form-one__group">
                  <input
                    type="text"
                    name="number"
                    id="form-one-subject-input"
                    placeholder="Whatsapp/Mobile Number"
                    className="form-one__input"
                  />
                </div>
                {/* /.form-one__group */}
              </div>
              {/* /.col-md-6 */}
              {/* /.col-md-6 */}
              <div
                className="col-md-6 wow animated fadeInUp"
                data-wow-delay="0.3s"
                data-wow-duration="1500ms"
              >
                <div className="form-one__group">
                  <input
                    type="text"
                    name="mehramsname"
                    id="form-one-subject-input"
                    placeholder="Mehram's Name"
                    className="form-one__input"
                  />
                </div>
                {/* /.form-one__group */}
              </div>
              {/* /.col-md-6 */}
              <div
                className="col-md-6 wow animated fadeInUp"
                data-wow-delay="0.3s"
                data-wow-duration="1500ms"
              >
                <div className="form-one__group">
                  <input
                    type="text"
                    name="relationwithmehram"
                    id="form-one-subject-input"
                    placeholder="Relation With Mehram"
                    className="form-one__input"
                  />
                </div>
                {/* /.form-one__group */}
              </div>
              <div
                className="col-md-6 wow animated fadeInUp"
                data-wow-delay="0.3s"
                data-wow-duration="1500ms"
              >
                <div classname="form-one__group">
                  <label htmlFor="" className="text-white">
                    Are You Married?
                  </label>
                  <select
                    className="form-one__input"
                    id="form-one-subject-input"
                    style={{ height: 60 }}
                  >
                    <option selected>Choose...</option>
                    <option value={1}>Married</option>
                    <option value={2}>Single</option>
                  </select>
                </div>

                {/* /.form-one__group */}
              </div>
              {/* /.col-md-6 */}
              <div
                className="col-md-6 wow animated fadeInUp"
                data-wow-delay="0.3s"
                data-wow-duration="1500ms"
              >
                <div classname="form-one__group">
                  <label htmlFor="" className="text-white">
                    Gender
                  </label>
                  <select
                    className="form-one__input"
                    id="form-one-subject-input"
                    style={{ height: 60 }}
                  >
                    <option selected>Choose...</option>
                    <option value={1}>Male</option>
                    <option value={2}>Female</option>
                  </select>
                </div>

                {/* /.form-one__group */}
              </div>
              {/* /.col-md-6 */}
              <div
                className="col-md-12 wow animated fadeInUp"
                data-wow-delay="0s"
                data-wow-duration="1500ms"
              >
                <div className="form-one__group">
                  <input
                    type="text"
                    name="caseofdeath"
                    id="form-one-phone-input"
                    placeholder="Nominee In Case Of Death"
                    className="form-one__input"
                  />
                </div>
                {/* /.form-one__group */}
              </div>
              <div
                className="col-md-12 wow animated fadeInUp"
                data-wow-delay="0s"
                data-wow-duration="1500ms"
              >
                <div className="form-one__group">
                  <input
                    type="text"
                    name="relationwithhercnic"
                    id="form-one-phone-input"
                    placeholder="RelationShip With Her Cnic Number"
                    className="form-one__input"
                  />
                </div>
                {/* /.form-one__group */}
              </div>
              <div
                className="col-12 wow animated fadeInUp"
                data-wow-delay="0.1s"
                data-wow-duration="1500ms"
              >
                <div className="form-one__group">
                  <textarea
                    name="message"
                    id="form-one-message-input"
                    cols={30}
                    rows={10}
                    placeholder="Write a Message"
                    className="form-one__message form-one__input"
                    defaultValue={""}
                  />
                </div>
                {/* /.form-one__group */}
              </div>
              {/* /.col-12*/}
              <div
                className="col-12 wow animated fadeInUp"
                data-wow-delay="0.2s"
                data-wow-duration="1500ms"
              >
                <div className="form-one__btn-box">
                  <button
                    type="submit"
                    className="form-one__btn trevlo-btn trevlo-btn--base"
                  >
                    <span>Send Message</span>
                  </button>
                </div>
                {/* /.form-one__btn-box */}
              </div>
              {/* /.col-12*/}
            </form>
            {/* /.row */}
            <div className="result" />
            {/* /.result */}
          </div>
          {/* /.container */}
          <div className="contact-page__info">
            <div className="contact-page__info-container container">
              <div className="contact-page__info-top">
                <p className="contact-page__info-top-title">
                  Contact Information
                </p>
              </div>
              {/* /.contact-page__info-top */}
              <div className="contact-page__info-row row">
                <div
                  className="col-lg-4 wow animated fadeInUp"
                  data-wow-delay="0s"
                  data-wow-duration="1500ms"
                >
                  <div className="contact-page__info-box">
                    <div className="contact-page__info-icon-box">
                      <span className="icon-phone-1" />
                    </div>
                    <div className="contact-page__info-text-box">
                      <p className="contact-page__info-title">
                        Have any question?
                      </p>
                      <a href="tel:+923224070193">
                        <p className="contact-page__info-text contact-page__info-text-link">
                          +923224070193
                        </p>
                      </a>
                    </div>
                  </div>
                  {/* /.contact-page__info-box */}
                </div>
                <div
                  className="col-lg-3 wow animated fadeInUp"
                  data-wow-delay="0.3s"
                  data-wow-duration="1500ms"
                >
                  <div className="contact-page__info-box">
                    <div className="contact-page__info-icon-box">
                      <span className="icon-envelope" />
                    </div>
                    <div className="contact-page__info-text-box">
                      <p className="contact-page__info-title">Send Email</p>
                      <a href="mailto:help@trevlo.com">
                        <p className="contact-page__info-text contact-page__info-text-link">
                          pakqurtuba@gmail.com
                        </p>
                      </a>
                    </div>
                  </div>
                  {/* /.contact-page__info-box */}
                </div>
                <div
                  className="col-lg-5 wow animated fadeInUp"
                  data-wow-delay="0.6s"
                  data-wow-duration="1500ms"
                >
                  <div className="contact-page__info-box contact-page__info-box--three">
                    <div className="contact-page__info-icon-box">
                      <span className="icon-location-1" />
                    </div>
                    <div className="contact-page__info-text-box">
                      <p className="contact-page__info-title">Visit Anytime</p>
                      <p className="contact-page__info-text">
                        Near Jazz Franchise Johar Town,Lahore, Pakistan
                      </p>
                    </div>
                  </div>
                  {/* /.contact-page__info-box */}
                </div>
              </div>
              {/* /.row */}
            </div>
            {/* /.container */}
          </div>
          {/* /.contact-page__info */}
          <div className="google-map google-map__@@extraClassName">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d235.56737789312402!2d74.27313421070579!3d31.4692908419389!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391903c379c19c35%3A0xe6dc183ed6ebacef!2sPak%20Qurtuba%20Travels%20(Pvt)%20Ltd!5e0!3m2!1sen!2s!4v1708864111073!5m2!1sen!2s"
              width={600}
              height={450}
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              title="officelocation"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
          {/* /.google-map */}
        </section>

        <Footer />
        <Mobilenav />
      </div>
    </div>
  );
}

export default HajjForm;
