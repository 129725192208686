import React from 'react'

function Clients() {
  return (
    <div>
     <div className="client-carousel @@extraClassName" style={{backgroundColor:"#241C26"}}>
  <div className="container">
    <h5 className="client-carousel__title"><span>2k + Brands Trust Us</span></h5>
    <div className="client-carousel__one trevlo-owl__carousel owl-theme owl-carousel" data-owl-options="{
      &quot;items&quot;: 5,
      &quot;margin&quot;: 65,
      &quot;smartSpeed&quot;: 700,
      &quot;loop&quot;:true,
      &quot;autoplay&quot;: 6000,
      &quot;nav&quot;:false,
      &quot;dots&quot;:false,
      &quot;navText&quot;: [&quot;<span class=\&quot;fa fa-angle-left\&quot;></span>&quot;,&quot;<span class=\&quot;fa fa-angle-right\&quot;></span>&quot;],
      &quot;responsive&quot;:{
          &quot;0&quot;:{
              &quot;items&quot;:1,
              &quot;margin&quot;: 0
          },
          &quot;450&quot;:{
              &quot;items&quot;:2,
              &quot;margin&quot;: 0
          },
          &quot;768&quot;:{
              &quot;items&quot;:3,
              &quot;margin&quot;: 40
          },
          &quot;992&quot;:{
              &quot;items&quot;: 4,
              &quot;margin&quot;: 40
          },
          &quot;1200&quot;:{
              &quot;items&quot;: 5
          }
      }
      }">
      <div className="client-carousel__one__item">
        <img className="client-carousel__one__item__normal" src="./assets/images/airlines/flynas.png" alt="" />
        <img className="client-carousel__one__item__normal" src="./assets/images/airlines/saudia.png" alt="" />
      </div>{/* /.owl-slide-item*/}
      <div className="client-carousel__one__item">
      <img className="client-carousel__one__item__normal" src="./assets/images/airlines/emirates.png" alt="" />
        <img className="client-carousel__one__item__normal" src="./assets/images/airlines/qatar.png" alt="" />

      </div>{/* /.owl-slide-item*/}
      <div className="client-carousel__one__item">
      <img className="client-carousel__one__item__normal" src="./assets/images/airlines/etihad.png" alt="" />
        <img className="client-carousel__one__item__normal" src="./assets/images/airlines/air-arabia.png" alt="" />
      </div>{/* /.owl-slide-item*/}
      <div className="client-carousel__one__item">
        <img className="client-carousel__one__item__normal" src="./assets/images/airlines/flynas.png" alt="" />
        <img className="client-carousel__one__item__normal" src="./assets/images/airlines/saudia.png" alt="" />
      </div>{/* /.owl-slide-item*/}
      <div className="client-carousel__one__item">
      <img className="client-carousel__one__item__normal" src="./assets/images/airlines/emirates.png" alt="" />
        <img className="client-carousel__one__item__normal" src="./assets/images/airlines/qatar.png" alt="" />
      </div>{/* /.owl-slide-item*/}
      <div className="client-carousel__one__item">
      <img className="client-carousel__one__item__normal" src="./assets/images/airlines/etihad.png" alt="" />
        <img className="client-carousel__one__item__normal" src="./assets/images/airlines/air-arabia.png" alt="" />
      </div>{/* /.owl-slide-item*/}
    
    </div>{/* /.thm-owl__slider */}
  </div>{/* /.container */}
</div>{/* /.client-carousel */}

    </div>
  )
}

export default Clients
