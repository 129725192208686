import React from "react";
import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";

function Gallery() {
  return (
    <div>
      <Navbar />
      <section className="page-header">
        <div className="page-header__bg" />
        {/* /.page-header__bg */}
        <div className="container">
          <h2
            className="page-header__title wow animated fadeInLeft"
            data-wow-delay="0s"
            data-wow-duration="1500ms"
          >
            Gallery
          </h2>
          <div className="page-header__breadcrumb-box">
            <ul className="trevlo-breadcrumb">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Gallery</li>
            </ul>
            {/* /.trevlo-breadcrumb */}
          </div>
          {/* /.page-header__breadcrumb-box */}
        </div>
        {/* /.container */}
      </section>
      {/* /.page-header */}

      {/* Gallery Page Start */}
      <div className="gallery-page section-space">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-4 col-lg-4 col-sm-6 wow animated fadeInUp"
              data-wow-delay="0.1s"
              data-wow-duration="1500ms"
            >
              <div className="gallery-single">
                <div className="gallery-single__img-box">
                  <img
                    src="assets/images/gallery/gallery-3-1.jpg"
                    alt="gallery"
                    className="gallery-single__img"
                  />
                  <div className="gallery-single__overlay">
                    <a
                      href="assets/images/gallery/gallery-3-1.jpg"
                      className="gallery-single__btn trevlo-btn trevlo-btn--base-three img-popup"
                    >
                      <span className="icon-plus" />
                    </a>
                  </div>
                  {/* /.gallery-single__overlay */}
                </div>
                {/* /.gallery-single__img-box */}
              </div>
              {/* /.gallery-single */}
            </div>
            {/* /.col-xl-4 col-lg-4 col-sm-6 */}
            <div
              className="col-xl-4 col-lg-4 col-sm-6 wow animated fadeInUp"
              data-wow-delay="0.3s"
              data-wow-duration="1500ms"
            >
              <div className="gallery-single">
                <div className="gallery-single__img-box">
                  <img
                    src="assets/images/gallery/gallery-3-2.jpg"
                    alt="gallery"
                    className="gallery-single__img"
                  />
                  <div className="gallery-single__overlay">
                    <a
                      href="assets/images/gallery/gallery-3-2.jpg"
                      className="gallery-single__btn trevlo-btn trevlo-btn--base-three img-popup"
                    >
                      <span className="icon-plus" />
                    </a>
                  </div>
                  {/* /.gallery-single__overlay */}
                </div>
                {/* /.gallery-single__img-box */}
              </div>
              {/* /.gallery-single */}
            </div>
            {/* /.col-xl-4 col-lg-4 col-sm-6 */}
            <div
              className="col-xl-4 col-lg-4 col-sm-6 wow animated fadeInUp"
              data-wow-delay="0.5s"
              data-wow-duration="1500ms"
            >
              <div className="gallery-single">
                <div className="gallery-single__img-box">
                  <img
                    src="assets/images/gallery/gallery-3-3.jpg"
                    alt="gallery"
                    className="gallery-single__img"
                  />
                  <div className="gallery-single__overlay">
                    <a
                      href="assets/images/gallery/gallery-3-3.jpg"
                      className="gallery-single__btn trevlo-btn trevlo-btn--base-three img-popup"
                    >
                      <span className="icon-plus" />
                    </a>
                  </div>
                  {/* /.gallery-single__overlay */}
                </div>
                {/* /.gallery-single__img-box */}
              </div>
              {/* /.gallery-single */}
            </div>
            {/* /.col-xl-4 col-lg-4 col-sm-6 */}
            <div
              className="col-xl-4 col-lg-4 col-sm-6 wow animated fadeInUp"
              data-wow-delay="0.1s"
              data-wow-duration="1500ms"
            >
              <div className="gallery-single">
                <div className="gallery-single__img-box">
                  <img
                    src="assets/images/gallery/gallery-3-4.jpg"
                    alt="gallery"
                    className="gallery-single__img"
                  />
                  <div className="gallery-single__overlay">
                    <a
                      href="assets/images/gallery/gallery-3-4.jpg"
                      className="gallery-single__btn trevlo-btn trevlo-btn--base-three img-popup"
                    >
                      <span className="icon-plus" />
                    </a>
                  </div>
                  {/* /.gallery-single__overlay */}
                </div>
                {/* /.gallery-single__img-box */}
              </div>
              {/* /.gallery-single */}
            </div>
            {/* /.col-xl-4 col-lg-4 col-sm-6 */}
            <div
              className="col-xl-4 col-lg-4 col-sm-6 wow animated fadeInUp"
              data-wow-delay="0.3s"
              data-wow-duration="1500ms"
            >
              <div className="gallery-single">
                <div className="gallery-single__img-box">
                  <img
                    src="assets/images/gallery/gallery-3-5.jpg"
                    alt="gallery"
                    className="gallery-single__img"
                  />
                  <div className="gallery-single__overlay">
                    <a
                      href="assets/images/gallery/gallery-3-5.jpg"
                      className="gallery-single__btn trevlo-btn trevlo-btn--base-three img-popup"
                    >
                      <span className="icon-plus" />
                    </a>
                  </div>
                  {/* /.gallery-single__overlay */}
                </div>
                {/* /.gallery-single__img-box */}
              </div>
              {/* /.gallery-single */}
            </div>
            {/* /.col-xl-4 col-lg-4 col-sm-6 */}
            <div
              className="col-xl-4 col-lg-4 col-sm-6 wow animated fadeInUp"
              data-wow-delay="0.5s"
              data-wow-duration="1500ms"
            >
              <div className="gallery-single">
                <div className="gallery-single__img-box">
                  <img
                    src="assets/images/gallery/gallery-3-6.jpg"
                    alt="gallery"
                    className="gallery-single__img"
                  />
                  <div className="gallery-single__overlay">
                    <a
                      href="assets/images/gallery/gallery-3-6.jpg"
                      className="gallery-single__btn trevlo-btn trevlo-btn--base-three img-popup"
                    >
                      <span className="icon-plus" />
                    </a>
                  </div>
                  {/* /.gallery-single__overlay */}
                </div>
                {/* /.gallery-single__img-box */}
              </div>
              {/* /.gallery-single */}
            </div>
            {/* /.col-xl-4 col-lg-4 col-sm-6 */}
            <div
              className="col-xl-4 col-lg-4 col-sm-6 wow animated fadeInUp"
              data-wow-delay="0.1s"
              data-wow-duration="1500ms"
            >
              <div className="gallery-single">
                <div className="gallery-single__img-box">
                  <img
                    src="assets/images/gallery/gallery-3-7.jpg"
                    alt="gallery"
                    className="gallery-single__img"
                  />
                  <div className="gallery-single__overlay">
                    <a
                      href="assets/images/gallery/gallery-3-7.jpg"
                      className="gallery-single__btn trevlo-btn trevlo-btn--base-three img-popup"
                    >
                      <span className="icon-plus" />
                    </a>
                  </div>
                  {/* /.gallery-single__overlay */}
                </div>
                {/* /.gallery-single__img-box */}
              </div>
              {/* /.gallery-single */}
            </div>
            {/* /.col-xl-4 col-lg-4 col-sm-6 */}
            <div
              className="col-xl-4 col-lg-4 col-sm-6 wow animated fadeInUp"
              data-wow-delay="0.3s"
              data-wow-duration="1500ms"
            >
              <div className="gallery-single">
                <div className="gallery-single__img-box">
                  <img
                    src="assets/images/gallery/gallery-3-8.jpg"
                    alt="gallery"
                    className="gallery-single__img"
                  />
                  <div className="gallery-single__overlay">
                    <a
                      href="assets/images/gallery/gallery-3-8.jpg"
                      className="gallery-single__btn trevlo-btn trevlo-btn--base-three img-popup"
                    >
                      <span className="icon-plus" />
                    </a>
                  </div>
                  {/* /.gallery-single__overlay */}
                </div>
                {/* /.gallery-single__img-box */}
              </div>
              {/* /.gallery-single */}
            </div>
            {/* /.col-xl-4 col-lg-4 col-sm-6 */}
            <div
              className="col-xl-4 col-lg-4 col-sm-6 wow animated fadeInUp"
              data-wow-delay="0.5s"
              data-wow-duration="1500ms"
            >
              <div className="gallery-single">
                <div className="gallery-single__img-box">
                  <img
                    src="assets/images/gallery/gallery-3-9.jpg"
                    alt="gallery"
                    className="gallery-single__img"
                  />
                  <div className="gallery-single__overlay">
                    <a
                      href="assets/images/gallery/gallery-3-9.jpg"
                      className="gallery-single__btn trevlo-btn trevlo-btn--base-three img-popup"
                    >
                      <span className="icon-plus" />
                    </a>
                  </div>
                  {/* /.gallery-single__overlay */}
                </div>
                {/* /.gallery-single__img-box */}
              </div>
              {/* /.gallery-single */}
            </div>
            {/* /.col-xl-4 col-lg-4 col-sm-6 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* /.gallery-page */}
      {/* Gallery Page End */}

      <Footer />
    </div>
  );
}

export default Gallery;
