import React from 'react'

function MainHero() {
  return (
    <div>
      <section class="main-slider-one">
	<div class="main-slider-one__carousel trevlo-owl__carousel owl-carousel owl-theme"
		data-owl-options='{
		"items": 1,
		"margin": 0,
		"loop": true,
		"smartSpeed": 700,
		"animateOut": "fadeOut",
		"autoplayTimeout": 5000, 
		"nav": true,
		"navText": ["<span class=\"icon-left-arrow\"></span>","<span class=\"icon-right-arrow\"></span>"],
		"dots": false,
		"autoplay": true
		}'>
		<div class="item">
			<div className="main-slider-one__image" style={{backgroundImage: 'url(./assets/images/backgrounds/makkah.webp)'}}>
</div>

            <div class="container">
                <div class="main-slider-one__content">
                    <h3 class="main-slider-one__sub-title">WELCOME<img src="./assets/images/shapes/slider-1-shape-1.png" alt="trevlo"/></h3>
                    <h5 className='main-slider-one__sub-title'>To</h5>
                   <div className='d-flex justify-content-center align-items-center align-content-center'>
                    <h5 class="main-slider-one__title">PAK QURTABA TRAVELS AND TOURS <img src="./assets/images/shapes/slider-1-shape-2.png" alt="trevlo"/></h5>
                    </div>
                </div>
            </div>
		</div>
		<div class="item">
			<div className="main-slider-one__image" style={{backgroundImage: 'url(./assets/images/backgrounds/madina.webp)'}}>
</div>

            <div class="container">
                <div class="main-slider-one__content">
                    <h5 class="main-slider-one__sub-title">Beauty & Discovery <img src="./assets/images/shapes/slider-1-shape-1.png" alt="trevlo"/></h5>
                    <h3 class="main-slider-one__title">Explore the World <img src="./assets/images/shapes/slider-1-shape-2.png" alt="trevlo"/></h3>
                </div>
            </div>
		</div>
		<div class="item">
			<div className="main-slider-one__image" style={{backgroundImage: 'url(./assets/images/backgrounds/bg4.webp)'}}>
</div>

            <div class="container">
                <div class="main-slider-one__content">
                    <h5 class="main-slider-one__sub-title">Beauty & Discovery <img src="./assets/images/shapes/slider-1-shape-1.png" alt="trevlo"/></h5>
                    <h3 class="main-slider-one__title">Explore the World <img src="./assets/images/shapes/slider-1-shape-2.png" alt="trevlo"/></h3>
                </div>
            </div>
		</div>
        
	</div>{/* banner-slider */}

    {/* <div class="banner-form wow fadeInUp" data-wow-delay="300ms"> */}
    {/* <div class="container">
        <form class="banner-form__wrapper" action="tour-listing-top-search.html">
            <div class="row">
                <div class="col-lg-4">
                    <div class="banner-form__control">
                        <label for="location">Location</label>
                        <select name="location" class="selectpicker" id="location">
                            <option value="select">Where to Next?</option>
                            <option value="spain">Spain</option>
                            <option value="africa">Africa</option>
                            <option value="europe">Europe</option>
                            <option value="thailand">Thailand</option>
                            <option value="dubai">Dubai</option>
                            <option value="australia">Australia</option>
                            <option value="swizerlan">Swizerlan</option>
                        </select>
                        <i class="icon-location-2"></i>
                    </div>
                </div>
                <div class="col-lg-2">
                    <div class="banner-form__control">
                        <label for="type">Type</label>
                        <select name="type" class="selectpicker" id="type">
                            <option value="select">Booking Type</option>
                            <option value="spain">Adventure</option>
                            <option value="africa">Beach</option>
                            <option value="europe">Discovery</option>
                            <option value="thailand">Mountain</option>
                            <option value="dubai">Hills</option>
                            <option value="australia">Couple</option>
                        </select>
                        <i class="icon-hiking"></i>
                    </div>
                </div>
                <div class="col-lg-2">
                    <div class="banner-form__control">
                        <label for="date">Date From</label>
                        <input class="trevlo-multi-datepicker" id="date" type="text" name="date" placeholder="Select Date"/>
                        <i class="icon-calendar-5"></i>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="banner-form__control">
                        <label for="guests">Guests</label>
                        <button class="banner-form__qty-minus sub">
                            <i class="icon-minus-3"></i>
                        </button>
                        <input id="guests" type="number" value="2" name="guests" placeholder="2"/>
                        <button class="banner-form__qty-plus add">
                            <i class="icon-plus-3"></i>
                        </button>
                    </div>
                </div>
                <div class="col-lg-1">
                    <div class="banner-form__btn">
                        <div class="banner-form__filter"><span class="icon-filter"></span></div>
                        <button type="submit" aria-label="search submit" class="trevlo-btn trevlo-btn--base">
                            <span><i class="icon-search"></i></span>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="banner-form__popup">
        <div class="banner-form__popup__slider-wrapper">
            <form action="#" method="get" class="clearfix">
                <div id="slider-ranger"></div>
            </form>
        </div>
        <h3 class="banner-form__popup__title">Language</h3>
        <ul class="banner-form__popup__checkbox">
            <li>
                <input type="checkbox" name="english" id="english"/>
                <label for="english"><span></span>English</label>
            </li>
            <li>
                <input type="checkbox" name="french" id="french"/>
                <label for="french"><span></span>French</label>
            </li>
            <li>
                <input type="checkbox" name="german" id="german"/>
                <label for="german"><span></span>German</label>
            </li>
            <li>
                <input type="checkbox" name="japanese" id="japanese"/>
                <label for="japanese"><span></span>Japanese</label>
            </li>
            <li>
                <input type="checkbox" name="thailand" id="thailand"/>
                <label for="thailand"><span></span>Thailand</label>
            </li>
            <li>
                <input type="checkbox" name="arabic" id="arabic"/>
                <label for="arabic"><span></span>Arabic</label>
            </li>
        </ul>
        <h3 class="banner-form__popup__title">Amenities</h3>
        <ul class="banner-form__popup__checkbox">
            <li>
                <input type="checkbox" name="Accepts" id="Accepts"/>
                <label for="Accepts"><span></span>Accepts Credit Cards</label>
            </li>
            <li>
                <input type="checkbox" name="Parking" id="Parking"/>
                <label for="Parking"><span></span>Car Parking</label>
            </li>
            <li>
                <input type="checkbox" name="Coupons" id="Coupons"/>
                <label for="Coupons"><span></span>Free Coupons</label>
            </li>
            <li>
                <input type="checkbox" name="Laundry" id="Laundry"/>
                <label for="Laundry"><span></span>Laundry Service</label>
            </li>
            <li>
                <input type="checkbox" name="Outdoor" id="Outdoor"/>
                <label for="Outdoor"><span></span>Outdoor Seating</label>
            </li>
            <li>
                <input type="checkbox" name="Reservations" id="Reservations"/>
                <label for="Reservations"><span></span>Reservations</label>
            </li>
            <li>
                <input type="checkbox" name="Restaurant" id="Restaurant"/>
                <label for="Restaurant"><span></span>Restaurant</label>
            </li>
            <li>
                <input type="checkbox" name="Smoking" id="Smoking"/>
                <label for="Smoking"><span></span>Smoking Allowed</label>
            </li>
            <li>
                <input type="checkbox" name="Internet" id="Internet"/>
                <label for="Internet"><span></span>Wireless Internet</label>
            </li>
        </ul>
    </div> */}
{/* </div> */}
    {/* <!-- banner-form --> */}
</section>
    </div>
  )
}

export default MainHero
