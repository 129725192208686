import React from "react";
import { Link } from "react-router-dom";
import { UilLocationPoint } from "@iconscout/react-unicons";
import { UilFastMail } from "@iconscout/react-unicons";
import { UilPhoneVolume } from "@iconscout/react-unicons";
import { UilClockEight } from "@iconscout/react-unicons";
import { UilSearch } from "@iconscout/react-unicons";
import { UilUser } from "@iconscout/react-unicons";
function Navbar() {
  return (
    <>
      <div>
        <div className="topbar-one">
          <div className="topbar-one__contaner container-fluid">
            <div className="topbar-one__inner">
              <div className="topbar-one__left">
                <ul className="topbar-one__info">
                  <li className="topbar-one__info-item">
                    <UilLocationPoint size="15" color="#FF5956" />
                    Near Jazz Franchise Johar Town,Lahore, Pakistan
                  </li>
                  <li className="topbar-one__info-item">
                    <UilFastMail size="18" color="#FF5956" />
                    <a
                      href="mailto:needhelp@company.com"
                      className="topbar-one__info-text ms-1"
                    >
                      pakqurtuba@hotmail.com
                    </a>
                  </li>
                </ul>
                {/* /.topbar-one__info */}
                <ul className="topbar-one__info topbar-one__info--right">
                  <li className="topbar-one__info-item">
                    <UilClockEight size="18" color="#FF5956" />
                    <span className="topbar-one__info-text ms-1">
                      Mon to Sat: 10.00 am - 8.00 pm
                    </span>
                  </li>
                </ul>
                {/* /.topbar-one__info */}
              </div>
              {/* /.topbar-one__left */}
              <div className="topbar-one__right">
                <ul className="topbar-one__social">
                  <li className="topbar-one__social-item">
                    <a
                      href="https://facebook.com"
                      className="topbar-one__social-link"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li className="topbar-one__social-item">
                    <a
                      href="https://twitter.com"
                      className="topbar-one__social-link"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li className="topbar-one__social-item">
                    <a
                      href="https://pinterest.com"
                      className="topbar-one__social-link"
                    >
                      <i className="fab fa-pinterest-p" />
                    </a>
                  </li>
                  <li className="topbar-one__social-item">
                    <a
                      href="https://instagram.com"
                      className="topbar-one__social-link"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                </ul>
                {/* /.topbar-one__social */}
              </div>
              {/* /.topbar-one__right */}
            </div>
            {/* /.topbar-one__inner */}
          </div>
          {/* /.ctopbar-one__contaner */}
        </div>
        {/* /.topbar-one */}
        <header className="main-header sticky-header sticky-header--normal">
          <div className="container">
            <div className="main-header__inner">
              <div className="main-header__left">
                <div className="main-header__logo">
                  <Link to="/">
                    <img
                      src="assets/images/logo.png"
                      alt="Trevlo HTML"
                      width={100}
                    />
                    <span className="text-dark fs-5 fw-semibold">
                      PAK QURTUBA
                    </span>
                  </Link>
                </div>
                {/* /.main-header__logo */}
                <nav className="main-header__nav main-menu">
                  <ul className="main-menu__list">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li className="dropdown">
                      <a href="#">Hajj</a>
                      <ul className="sub-menu">
                        <li>
                          <a href="/hajjregistration">Hajj Registration Form</a>
                        </li>
                        {/* <li className="dropdown">
                    <a href="#">Destination Details</a>
                    <ul className="sub-menu">
                      <li><a href="destination-details.html">No Sidebar</a></li>
                      <li><a href="destination-details-left.html">Left Sidebar</a></li>
                      <li><a href="destination-details-right.html">Right Sidebar</a></li>
                    </ul>
                  </li> */}
                      </ul>
                    </li>
                    <li className="dropdown">
                      <a href="/hotels">Hotels</a>
                      <ul className="sub-menu">
                        <li>
                          <a href="/makkahhotels">Makkah Hotels</a>
                        </li>
                        <li>
                          <a href="/madinahotels">Madina Hotels</a>
                        </li>
                        {/* <li className="dropdown">
                    <a href="#">Destination Details</a>
                    <ul className="sub-menu">
                      <li><a href="destination-details.html">No Sidebar</a></li>
                      <li><a href="destination-details-left.html">Left Sidebar</a></li>
                      <li><a href="destination-details-right.html">Right Sidebar</a></li>
                    </ul>
                  </li> */}
                      </ul>
                    </li>
                    <li>
                      <a href="/umrah">Umrah</a>
                    </li>
                    <li>
                      <a href="/aboutus">About</a>
                    </li>
                    <li>
                      <a href="/contactus">Contact</a>
                    </li>
                    <li>
                      <a href="/gallery">Gallery</a>
                    </li>
                  </ul>
                </nav>
                {/* /.main-header__nav */}
              </div>
              {/* /.main-header__left */}
              <div className="main-header__right">
                <div className="mobile-nav__btn mobile-nav__toggler">
                  <span />
                  <span />
                  <span />
                </div>
                {/* /.mobile-nav__toggler */}
                <a
                  href="tour-listing-side-filter-right.html"
                  className="main-header__booking-btn trevlo-btn trevlo-btn--white-two"
                >
                  <span>Start Booking</span>
                </a>
                {/* /.main-header__btn */}
                <div className="main-header__right-right">
                  <div className="main-header__phone">
                    <div className="main-header__phone-icon">
                      <UilPhoneVolume size="25" color="#FF5956" />
                    </div>
                    <div className="main-header__phone-text">
                      <p className="main-header__phone-title">Call Anytime</p>
                      <h4 className="main-header__phone-number">
                        <a href="tel:(+92)3224070193">+923224070193</a>
                      </h4>
                    </div>
                  </div>
                  {/* /.main-header__phone */}
                  <div className="main-header__divider" />
                  <ul className="main-header__search-user">
                    <li className="main-header__search-user-item">
                      <a
                        href="#"
                        className="main-header__search search-toggler"
                      >
                        <UilSearch size="25" color="white" />
                      </a>
                    </li>
                    <li className="main-header__search-user-item">
                      <a href="login.html" className="main-header__user">
                        <UilUser size="25" color="white" />
                      </a>
                    </li>
                  </ul>
                  {/* /.main-header__search-user */}
                </div>
                {/* /.main-header__right-right */}
              </div>
              {/* /.main-header__right */}
            </div>
            {/* /.main-header__inner */}
          </div>
          {/* /.container-fluid */}
        </header>
        {/* /.main-header */}
      </div>
    </>
  );
}

export default Navbar;
