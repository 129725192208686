import React from 'react'

function TourListing() {
  return (
    <div>
     <section className="tour-listing-one" style={{backgroundImage: 'url(./assets/images/backgrounds/tour-bg-1-dark.jpg)',backgroundColor:"#241C26" }} >
  <div className="container">
    <div className="sec-title text-center">
      <p className="sec-title__tagline">Featured tours</p>{/* /.sec-title__tagline */}
      <h2 className="sec-title__title text-white">Most Favorite Tour Place</h2>{/* /.sec-title__title */}
    </div>{/* /.sec-title */}{/* /.sec-title */}
    <div className="tour-listing-one__carousel trevlo-owl__carousel trevlo-owl__carousel--basic-nav trevlo-owl__carousel--with-shadow owl-theme owl-carousel" data-owl-options="{
      &quot;items&quot;: 3,
      &quot;margin&quot;: 30,
      &quot;smartSpeed&quot;: 700,
      &quot;loop&quot;:false,
      &quot;autoplay&quot;: 6000,
      &quot;nav&quot;:false,
      &quot;dots&quot;:true,
      &quot;navText&quot;: [&quot;<span class=\&quot;fa fa-angle-left\&quot;></span>&quot;,&quot;<span class=\&quot;fa fa-angle-right\&quot;></span>&quot;],
      &quot;responsive&quot;:{
          &quot;0&quot;:{
              &quot;items&quot;: 1
          },
          &quot;768&quot;:{
              &quot;items&quot;: 2
          },
          &quot;1200&quot;:{
              &quot;items&quot;: 3
          }
      }
      }">
      <div className="item" >
        <div className="tour-listing__card">
          <a href="tour-listing-details-right.html" className="tour-listing__card-image-box">
            <img src="./assets/images/tours/tour-3-1.jpg" alt="./assets/images/tours/tour-3-1.jpg" className="tour-listing__card-image" />
            <div className="tour-listing__card-btn-group">
              <div className="tour-listing__card-discount @@innerExtraClassName">10% Off</div>
              <div className="tour-listing__card-featured">Featured</div>
            </div>{/* /.tour-listing__card-btn-group */}
            <div className="tour-listing__card-image-overlay" />{/* /.tour-listing__card-image-overlay */}
          </a>{/* /.tour-listing__card-image-box */}
          <a href="" className="tour-listing__card-wishlist"><span className="icon-heart" /></a>
          <div className="tour-listing__card-content">
            <div className="tour-listing__card-camera-group">
              <a href="" className="tour-listing__card-camera-btn trevlo-image-popup" data-gallery-options="{
          &quot;items&quot;: [
            {
              &quot;src&quot;: &quot;./assets/images/tours/tour-3-1.jpg&quot;
            },
            {
              &quot;src&quot;: &quot;./assets/images/tours/tour-3-2.jpg&quot;
            },
            {
              &quot;src&quot;: &quot;./assets/images/tours/tour-3-3.jpg&quot;
            }
          ],
          &quot;gallery&quot;: {
            &quot;enabled&quot;: true
          },
          &quot;type&quot;: &quot;image&quot;
      }">
                <span className="icon-photo-camera-1" />
              </a>
              <a href="https://www.youtube.com/watch?v=h9MbznbxlLc" className="tour-listing__card-camera-btn video-popup">
                <span className="icon-video-camera-1-1" />
              </a>
            </div>{/* /.tour-listing__card-camera-group */}
            <h3 className="tour-listing__card-title" ><a href="tour-listing-details-right.html">Turkey Balloons flying</a></h3>
            <p className="tour-listing__card-text text-small">There are many variations of passages of Lorem Ipsum agtilable</p>
            <div className="tour-listing__card-inner-content" style={{backgroundColor:"#241C26"}}>
              <div className="tour-listing__card-review-box" >
                <span className="icon-star" />
                <p className="tour-listing__card-review-text text-small">4.5 (30 Reviews)</p>
              </div>{/* /.tour-listing__card-review-box */}
              <div className="tour-listing__card-location-box">
                <span className="icon-location-1" />
                <p className="tour-listing__card-location-text text-small">Main Street, Brooklyn, NY</p>
              </div>{/* /.tour-listing__card-location-box */}
              <div className="tour-listing__card-divider" />{/* /.tour-listing__card-divider */}
              <div className="tour-listing__card-bottom">
                <div className="tour-listing__card-bottom-left">
                  <div className="tour-listing__card-day">
                    <span className="icon-clock-1" />
                    <p className="tour-listing__card-day-text text-small">3 Day</p>
                  </div>{/* /.tour-listing__card-day */}
                  <div className="tour-listing__card-people">
                    <span className="icon-Duration" />
                    <p className="tour-listing__card-people-text text-small">12+</p>
                  </div>{/* /.tour-listing__card-people */}
                </div>{/* /.tour-listing__card-bottom-left */}
                <div className="tour-listing__card-bottom-right">
                  <h4 className="tour-listing__card-price text-white">$160</h4>
                </div>{/* /.tour-listing__card-bottom-right */}
              </div>{/* /.tour-listing__card-bottom */}
            </div>{/* /.tour-listing__card-inner-content */}
          </div>{/* /.tour-listing__card-content */}
        </div>{/* /.tour-listing__card */}
      </div>{/* /.item */}
      <div className="item">
        <div className="tour-listing__card">
          <a href="tour-listing-details-right.html" className="tour-listing__card-image-box">
            <img src="./assets/images/tours/tour-3-2.jpg" alt="./assets/images/tours/tour-3-2.jpg" className="tour-listing__card-image" />
            <div className="tour-listing__card-btn-group">
              <div className="tour-listing__card-featured">Featured</div>
            </div>{/* /.tour-listing__card-btn-group */}
            <div className="tour-listing__card-image-overlay" />{/* /.tour-listing__card-image-overlay */}
          </a>{/* /.tour-listing__card-image-box */}
          <a href="javascript:void(0);" className="tour-listing__card-wishlist"><span className="icon-heart" /></a>
          <div className="tour-listing__card-content ">
            <div className="tour-listing__card-camera-group ">
              <a href="javascript:void(0);" className="tour-listing__card-camera-btn trevlo-image-popup" data-gallery-options="{
          &quot;items&quot;: [
            {
              &quot;src&quot;: &quot;./assets/images/tours/tour-3-2.jpg&quot;
            },
            {
              &quot;src&quot;: &quot;./assets/images/tours/tour-3-3.jpg&quot;
            },
            {
              &quot;src&quot;: &quot;./assets/images/tours/tour-3-4.jpg&quot;
            }
          ],
          &quot;gallery&quot;: {
            &quot;enabled&quot;: true
          },
          &quot;type&quot;: &quot;image&quot;
      }">
                <span className="icon-photo-camera-1" />
              </a>
              <a href="https://www.youtube.com/watch?v=h9MbznbxlLc" className="tour-listing__card-camera-btn video-popup">
                <span className="icon-video-camera-1-1" />
              </a>
            </div>{/* /.tour-listing__card-camera-group */}
            <h3 className="tour-listing__card-title"><a href="tour-listing-details-right.html">Moscow Red City Land</a></h3>
            <p className="tour-listing__card-text text-small">There are many variations of passages of Lorem Ipsum agtilable</p>
            <div className="tour-listing__card-inner-content" style={{backgroundColor:"#241C26"}}>
              <div className="tour-listing__card-review-box">
                <span className="icon-star" />
                <p className="tour-listing__card-review-text text-small">4.5 (30 Reviews)</p>
              </div>{/* /.tour-listing__card-review-box */}
              <div className="tour-listing__card-location-box">
                <span className="icon-location-1" />
                <p className="tour-listing__card-location-text text-small">Main Street, Brooklyn, NY</p>
              </div>{/* /.tour-listing__card-location-box */}
              <div className="tour-listing__card-divider" />{/* /.tour-listing__card-divider */}
              <div className="tour-listing__card-bottom">
                <div className="tour-listing__card-bottom-left">
                  <div className="tour-listing__card-day">
                    <span className="icon-clock-1" />
                    <p className="tour-listing__card-day-text text-small">3 Day</p>
                  </div>{/* /.tour-listing__card-day */}
                  <div className="tour-listing__card-people">
                    <span className="icon-Duration" />
                    <p className="tour-listing__card-people-text text-small">12+</p>
                  </div>{/* /.tour-listing__card-people */}
                </div>{/* /.tour-listing__card-bottom-left */}
                <div className="tour-listing__card-bottom-right">
                  <h4 className="tour-listing__card-price text-white">$110</h4>
                </div>{/* /.tour-listing__card-bottom-right */}
              </div>{/* /.tour-listing__card-bottom */}
            </div>{/* /.tour-listing__card-inner-content */}
          </div>{/* /.tour-listing__card-content */}
        </div>{/* /.tour-listing__card */}
      </div>{/* /.item */}
      <div className="item">
        <div className="tour-listing__card">
          <a href="tour-listing-details-right.html" className="tour-listing__card-image-box">
            <img src="./assets/images/tours/tour-3-3.jpg" alt="./assets/images/tours/tour-3-3.jpg" className="tour-listing__card-image" />
            <div className="tour-listing__card-btn-group">
            </div>{/* /.tour-listing__card-btn-group */}
            <div className="tour-listing__card-image-overlay" />{/* /.tour-listing__card-image-overlay */}
          </a>{/* /.tour-listing__card-image-box */}
          <a href="javascript:void(0);" className="tour-listing__card-wishlist"><span className="icon-heart" /></a>
          <div className="tour-listing__card-content">
            <div className="tour-listing__card-camera-group">
              <a href="javascript:void(0);" className="tour-listing__card-camera-btn trevlo-image-popup" data-gallery-options="{
          &quot;items&quot;: [
            {
              &quot;src&quot;: &quot;./assets/images/tours/tour-3-3.jpg&quot;
            },
            {
              &quot;src&quot;: &quot;./assets/images/tours/tour-3-4.jpg&quot;
            },
            {
              &quot;src&quot;: &quot;./assets/images/tours/tour-3-5.jpg&quot;
            }
          ],
          &quot;gallery&quot;: {
            &quot;enabled&quot;: true
          },
          &quot;type&quot;: &quot;image&quot;
      }">
                <span className="icon-photo-camera-1" />
              </a>
              <a href="https://www.youtube.com/watch?v=h9MbznbxlLc" className="tour-listing__card-camera-btn video-popup">
                <span className="icon-video-camera-1-1" />
              </a>
            </div>{/* /.tour-listing__card-camera-group */}
            <h3 className="tour-listing__card-title"><a href="tour-listing-details-right.html">Turkey Balloons flying</a></h3>
            <p className="tour-listing__card-text text-small">There are many variations of passages of Lorem Ipsum agtilable</p>
            <div className="tour-listing__card-inner-content" style={{backgroundColor:"#241C26"}}>
              <div className="tour-listing__card-review-box">
                <span className="icon-star" />
                <p className="tour-listing__card-review-text text-small">4.5 (30 Reviews)</p>
              </div>{/* /.tour-listing__card-review-box */}
              <div className="tour-listing__card-location-box">
                <span className="icon-location-1" />
                <p className="tour-listing__card-location-text text-small">Main Street, Brooklyn, NY</p>
              </div>{/* /.tour-listing__card-location-box */}
              <div className="tour-listing__card-divider" />{/* /.tour-listing__card-divider */}
              <div className="tour-listing__card-bottom">
                <div className="tour-listing__card-bottom-left">
                  <div className="tour-listing__card-day">
                    <span className="icon-clock-1" />
                    <p className="tour-listing__card-day-text text-small">3 Day</p>
                  </div>{/* /.tour-listing__card-day */}
                  <div className="tour-listing__card-people">
                    <span className="icon-Duration" />
                    <p className="tour-listing__card-people-text text-small">12+</p>
                  </div>{/* /.tour-listing__card-people */}
                </div>{/* /.tour-listing__card-bottom-left */}
                <div className="tour-listing__card-bottom-right">
                  <h4 className="tour-listing__card-price text-white">$130</h4>
                </div>{/* /.tour-listing__card-bottom-right */}
              </div>{/* /.tour-listing__card-bottom */}
            </div>{/* /.tour-listing__card-inner-content */}
          </div>{/* /.tour-listing__card-content */}
        </div>{/* /.tour-listing__card */}
      </div>{/* /.item */}
    </div>{/* /.tour-listing-one__carousel */}
  </div>{/* /.container */}
</section>

    </div>
  )
}

export default TourListing
