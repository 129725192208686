import React from 'react'
import Loader from '../components/common/Loader'
import Navbar from '../components/common/Navbar'
import MainHero from '../components/common/MainHero'
import DestinationList from '../components/common/DestinationList'
import About from '../components/common/About'
import TourListing from '../components/common/TourListing'
import WhyOurCompany from '../components/common/WhyOurCompany'
import Testimonials from '../components/common/Testimonials'
import TourTypes from '../components/common/TourTypes'
import BookingOffer from '../components/common/BookingOffer'
// import Blog from '../components/common/Blog'
import Clients from '../components/common/Clients'
import Gallery from '../components/common/Gallery'
import Footer from '../components/common/Footer'
import Mobilenav from '../components/common/Mobilenav'

function CombineComp() {
  
  return (
    <div>
    <div class="page-wrapper">
      <Loader/>
      <Navbar/>
      <MainHero/>
      <DestinationList/>
      <About/>
      <TourListing/>
      <WhyOurCompany/>
      <Testimonials/>
      <TourTypes/>
      <BookingOffer/>
      {/* <Blog/> */}
      <Clients/>
      <Gallery/>
      <Footer/>
      </div>
      <Mobilenav/>
    </div>
  )
}

export default CombineComp
