import React from 'react'

function Gallery() {
  return (
    <div>
  <section className="gallery-one" style={{backgroundColor:"#241C26"}}>
  <div className="container">
    <div className="gallery-one__carousel trevlo-owl__carousel trevlo-owl__carousel--basic-nav owl-carousel" data-owl-options="{
			&quot;loop&quot;: true,
			&quot;items&quot;: 5,
			&quot;autoplay&quot;: true,
			&quot;smartSpeed&quot;: 600,
			&quot;nav&quot;: false,
			&quot;navText&quot;: [&quot;<span class=\&quot;icon-left-arrow\&quot;></span>&quot;,&quot;<span class=\&quot;icon-right-arrow\&quot;></span>&quot;],
			&quot;dots&quot;: true,
			&quot;margin&quot;: 10,
			&quot;responsive&quot;: {
				&quot;0&quot;: {
					&quot;items&quot;: 1
				},
				&quot;576&quot;: {
					&quot;items&quot;: 2
				},
				&quot;992&quot;: {
					&quot;items&quot;: 3
				},
				&quot;1200&quot;: {
					&quot;items&quot;: 4
				},
				&quot;1400&quot;: {
					&quot;items&quot;: 5
				}
			}
			}">
      <div className="item">
        <div className="gallery-single">
          <div className="gallery-single__img-box">
            <img src="./assets/images/gallery/gallery-1-1.jpg" alt="gallery" className="gallery-single__img" />
            <div className="gallery-single__overlay">
              <a href="./assets/images/gallery/gallery-1-1.jpg" className="gallery-single__btn trevlo-btn trevlo-btn--base-three img-popup"><span className="icon-plus" /></a>
            </div>{/* /.gallery-single__overlay */}
          </div>{/* /.gallery-single__img-box */}
        </div>{/* /.gallery-single */}
      </div>{/* /.item */}
      <div className="item">
        <div className="gallery-single">
          <div className="gallery-single__img-box">
            <img src="./assets/images/gallery/gallery-1-2.jpg" alt="gallery" className="gallery-single__img" />
            <div className="gallery-single__overlay">
              <a href="./assets/images/gallery/gallery-1-2.jpg" className="gallery-single__btn trevlo-btn trevlo-btn--base-three img-popup"><span className="icon-plus" /></a>
            </div>{/* /.gallery-single__overlay */}
          </div>{/* /.gallery-single__img-box */}
        </div>{/* /.gallery-single */}
      </div>{/* /.item */}
      <div className="item">
        <div className="gallery-single">
          <div className="gallery-single__img-box">
            <img src="./assets/images/gallery/gallery-1-3.jpg" alt="gallery" className="gallery-single__img" />
            <div className="gallery-single__overlay">
              <a href="./assets/images/gallery/gallery-1-3.jpg" className="gallery-single__btn trevlo-btn trevlo-btn--base-three img-popup"><span className="icon-plus" /></a>
            </div>{/* /.gallery-single__overlay */}
          </div>{/* /.gallery-single__img-box */}
        </div>{/* /.gallery-single */}
      </div>{/* /.item */}
      <div className="item">
        <div className="gallery-single">
          <div className="gallery-single__img-box">
            <img src="./assets/images/gallery/gallery-1-4.jpg" alt="gallery" className="gallery-single__img" />
            <div className="gallery-single__overlay">
              <a href="./assets/images/gallery/gallery-1-4.jpg" className="gallery-single__btn trevlo-btn trevlo-btn--base-three img-popup"><span className="icon-plus" /></a>
            </div>{/* /.gallery-single__overlay */}
          </div>{/* /.gallery-single__img-box */}
        </div>{/* /.gallery-single */}
      </div>{/* /.item */}
      <div className="item">
        <div className="gallery-single">
          <div className="gallery-single__img-box">
            <img src="./assets/images/gallery/gallery-1-5.jpg" alt="gallery" className="gallery-single__img" />
            <div className="gallery-single__overlay">
              <a href="./assets/images/gallery/gallery-1-5.jpg" className="gallery-single__btn trevlo-btn trevlo-btn--base-three img-popup"><span className="icon-plus" /></a>
            </div>{/* /.gallery-single__overlay */}
          </div>{/* /.gallery-single__img-box */}
        </div>{/* /.gallery-single */}
      </div>{/* /.item */}
    </div>
  </div>
</section>

    </div>
  )
}

export default Gallery
