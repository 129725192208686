import React, { useState, useEffect } from 'react';

function Loader() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate some asynchronous process
    const fakeAsyncProcess = async () => {
      // Simulate waiting for 2 seconds
      await new Promise((resolve) => setTimeout(resolve, 3000));

      // After the asynchronous process is complete, hide the loader
      setLoading(false);
    };

    // Call the asynchronous process
    fakeAsyncProcess();
  }, []); // Empty dependency array means this effect runs only once, similar to componentDidMount

  if (loading) {
    return (
      <div className="preloader">
        <div className="preloader__image" style={{ backgroundImage: 'url(./assets/images/logo.png)', height: '200px', width: '300px' }}>
        </div>
      </div>
    );
  }

  // If loading is false, return null (no loader will be displayed)
  return null;
}

export default Loader;
